import { updateCategory, updateDropdownOptions } from './discover.actions';

const onEnterDefault = [
    '$ngRedux',
    '$pfEntryCategories',
    '$pfLayout',
    '$stateParams',
    ($ngRedux, $pfEntryCategories, $pfLayout, $stateParams) => {
        // Set page title
        $pfLayout.title = DEFAULT_TITLE;

        // Make sure categories list is loaded, then update the mobile dropdown list
        $pfEntryCategories.getAllCategories().then(categories => {
            $ngRedux.dispatch(updateDropdownOptions({}));
        });
    },
];

const DEFAULT_TITLE = 'Discover Great Talent';

export const DISCOVER_ROUTES = {
    discover: {
        abstract: true,
        parent: 'layout.standard',
        views: {
            'navbarMobile@layout.standard': {
                // component: 'pfNavbarMobile',
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            <pf-discover-navbar-category-dropdown></pf-discover-navbar-category-dropdown>
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'app@layout.standard': {
                // component: 'pfDiscover',
                template: `<pf-discover></pf-discover>`,
            },
        },
        data: {
            appClass: 'discover',
            module: 'discover',
            sectionName: 'Discover',
        },
    },
    'discover.lander': {
        url: '/discover',
        views: {
            header: {
                // component: 'pfDiscoverHeader',
                template: `
                    <pf-discover-header
                         headline='{{ "Discover" | i18n }}'
                         subhead='{{ "Proudly showcasing thousands of projects, skills and experiences!" | i18n }}'>
                    </pf-discover-header>
                `,
            },
            main: {
                // component: 'pfDiscoverLander',
                template: `<pf-discover-lander categories="$ctrl.categories"></pf-discover-lander>`,
            },
        },
        onEnter: onEnterDefault,
    },
    'discover.all': {
        url: '/discover/all',
        views: {
            header: {
                // component: 'pfDiscoverHeader',
                template: `
                    <pf-discover-header
                         headline='{{ "Discover" | i18n }}'
                         subhead='{{ "Proudly showcasing thousands of projects, skills and experiences!" | i18n }}'>
                    </pf-discover-header>
                `,
            },
            main: {
                // component: 'pfDiscoverEntriesFeed',
                template: `
                    <pf-discover-entries-feed
                        type="all">
                    </pf-discover-entries-feed>
                `,
            },
        },
    },
    'discover.expertPicks': {
        url: '/discover/expert-picks',
        views: {
            header: {
                // component: 'pfDiscoverHeader',
                template: `
                    <pf-discover-header
                         headline='{{ "Expert Picks" | i18n }}'>
                    </pf-discover-header>
                `,
            },
            main: {
                // component: 'pfDiscoverEntriesFeed',
                template: `
                    <pf-discover-entries-feed
                        type="expert">
                    </pf-discover-entries-feed>
                `,
            },
        },
    },
    'discover.staffPicks': {
        url: '/discover/staff-picks',
        views: {
            header: {
                // component: 'pfDiscoverHeader',
                template: `
                    <pf-discover-header
                         headline='{{ "Staff Picks" | i18n }}'>
                    </pf-discover-header>
                `,
            },
            main: {
                // component: 'pfDiscoverEntriesFeed',
                template: `
                    <pf-discover-entries-feed
                        type="staff">
                    </pf-discover-entries-feed>
                `,
            },
        },
    },
    'discover.tag': {
        url: '/discover/tag/{tag:string}',
        resolve: {
            tag: ['$stateParams', $stateParams => $stateParams.tag],
        },
        views: {
            header: {
                // component: 'pfDiscoverHeader',
                template: `
                    <pf-discover-header
                         headline="#{{$resolve.tag}}">
                    </pf-discover-header>
                `,
            },
            main: {
                // component: 'pfDiscoverEntriesFeed',
                template: `
                    <pf-discover-entries-feed
                        type="tag"
                        tag="$resolve.tag">
                    </pf-discover-entries-feed>
                `,
            },
        },
        onEnter: [
            '$ngRedux',
            '$pfEntryCategories',
            '$pfLayout',
            '$transition$',
            ($ngRedux, $pfEntryCategories, $pfLayout, $transition$) => {
                $pfLayout.title = `#${$transition$.params().tag}`;

                $pfEntryCategories.getAllCategories().then(categories => {
                    $ngRedux.dispatch(updateDropdownOptions({}));
                });
            },
        ],
    },
    'discover.skill': {
        url: '/discover/skill?q&category',
        resolve: {
            skill: ['$stateParams', $stateParams => $stateParams.q],
            category_id: [
                '$stateParams',
                $stateParams => $stateParams.category,
            ],
        },
        views: {
            header: {
                // component: 'pfDiscoverHeader',
                template: `
                    <pf-discover-header
                         headline="{{$resolve.skill}}">
                    </pf-discover-header>
                `,
            },
            main: {
                // component: 'pfDiscoverEntriesFeed',
                template: `
                    <pf-discover-entries-feed
                        type="skill"
                        skill="$resolve.skill"
                        category-id="$resolve.category_id">
                    </pf-discover-entries-feed>
                `,
            },
        },
        onEnter: [
            '$ngRedux',
            '$pfEntryCategories',
            '$pfLayout',
            '$transition$',
            ($ngRedux, $pfEntryCategories, $pfLayout, $transition$) => {
                $pfLayout.title = `Proven ${$transition$.params().q} skills`;

                $pfEntryCategories.getAllCategories().then(categories => {
                    $ngRedux.dispatch(updateDropdownOptions({}));
                });
            },
        ],
    },
    'discover.category': {
        abstract: true,
        url: '/discover/category/{slug:string}',
        resolve: {
            slug: ['$transition$', $transition$ => $transition$.params().slug],
        },
        views: {
            header: {
                // component: 'pfDiscoverCategoryHeader',
                template: `
                    <pf-discover-category-header>
                    </pf-discover-category-header>
                `,
            },
            main: {
                // component: 'pfDiscoverCategory',
                template: `
                    <pf-discover-category
                         slug="$resolve.slug">
                    </pf-discover-category>
                `,
            },
        },
        onEnter: [
            '$ngRedux',
            '$pfEntryCategories',
            '$pfLayout',
            '$transition$',
            ($ngRedux, $pfEntryCategories, $pfLayout, $transition$) => {
                $pfEntryCategories.getAllCategories().then(categories => {
                    const category = _.find(categories.data, {
                        slug: $transition$.params().slug,
                    });

                    $pfLayout.title = category.category;

                    $ngRedux.dispatch(updateCategory(category));
                });
            },
        ],
        onExit: ['$ngRedux', $ngRedux => $ngRedux.dispatch(updateCategory({}))],
    },
    'discover.category.lander': {
        // component: 'pfDiscoverCategoryLander',
        template: `
            <pf-discover-category-lander
                 category="$ctrl.category">
            </pf-discover-category-lander>
        `,
    },
    'discover.category.feed': {
        url: '/feed',
        // component: 'pfDiscoverEntriesFeed',
        template: `
            <pf-discover-entries-feed
                 type="category"
                 slug="$resolve.slug">
            </pf-discover-entries-feed>
        `,
    },
};
