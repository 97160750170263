import {
    resetEditor,
    setStatusPublished,
    setStatusIsAssignment,
    setEntryClassification,
    setAllowedFileUploadTypes,
    setStatusNextState,
    setStrength,
    toggleLoading,
    updateAssignmentDetails,
    updateDraft,
    updateEntryDetails,
} from './entry-editor.actions';

export const ENTRY_EDITOR_ROUTES = {
    entryEditor: {
        parent: 'layout.empty',
        url: '/project',
        views: {
            'app@layout.empty': {
                template: `<pf-entry-editor></pf-entry-editor>`,
            },
        },
        params: {
            source: null, // Used for event tracking purposes
            redirectRoute: {
                stateName: null,
                params: {},
            },
            openSettings: false, // open settings after editor loads
        },
        data: {
            appClass: 'entry-editor',
            module: 'entry-editor',
        },
        onEnter: [
            '$ngRedux',
            '$transition$',
            ($ngRedux, $transition$) => {
                // Clear the draft
                $ngRedux.dispatch(resetEditor());
                // Set the next router state to go to when finished
                if (!_.isEmpty($transition$.params().redirectRoute)) {
                    $ngRedux.dispatch(
                        setStatusNextState(
                            $transition$.params().redirectRoute.stateName,
                            $transition$.params().redirectRoute.params,
                        ),
                    );
                }
            },
        ],
        onExit: [
            '$ngRedux',
            '$pfEntrySettingsModal',
            ($ngRedux, $pfEntrySettingsModal) => {
                // close the settings panel, if needed
                $pfEntrySettingsModal.close();
                // Clear the draft
                $ngRedux.dispatch(resetEditor());
            },
        ],
    },
    'entryEditor.draft': {
        url: '/draft/{draftId:string}?challengeId&tags&skills',
        params: {
            draftId: {
                value: null,
                squash: true,
            },
            attachments: { array: true },
            bucketId: null,
            challengeId: null,
            skills: { array: true },
            tags: { array: true },
        },
        onEnter: [
            '$ngRedux',
            '$pfEntryAttachment',
            '$pfEntryDrafts',
            '$pfEntryStrength',
            '$pfLayout',
            '$transition$',
            '$state',
            (
                $ngRedux,
                $pfEntryAttachment,
                $pfEntryDrafts,
                $pfEntryStrength,
                $pfLayout,
                $transition$,
                $state,
            ) => {
                // Get all state params
                const {
                    attachments,
                    bucketId,
                    challengeId,
                    draftId,
                    tags,
                    skills,
                } = $transition$.params();

                // Set page title
                $pfLayout.title = 'Add Project';

                // If creating a brand new draft, no API call needed
                if (!_.isString(draftId)) {
                    // Construct a blank draft
                    const {
                        draft,
                        entryDetails,
                    } = $pfEntryDrafts.getBlankDraft({
                        attachments,
                        bucketId,
                        challengeId,
                        tags,
                        skills,
                    });

                    // Update state with draft
                    $ngRedux.dispatch(updateDraft(draft));
                    // Update state with entry details
                    $ngRedux.dispatch(updateEntryDetails(entryDetails));

                    return;
                }

                // Set the loading flag
                $ngRedux.dispatch(toggleLoading());

                // Fetch a draft using the entry ID
                $pfEntryDrafts
                    .getDraftByDraftId(draftId)
                    .then(({ draft, entryDetails }) => {
                        if (draft.attachments) {
                            // Guarantee uniqueness
                            draft.attachments = $pfEntryAttachment.cleanAttachments(
                                draft.attachments,
                            );
                        }
                        // calculate the strength of the draft
                        $pfEntryStrength
                            .calculateDraftStrength(draft)
                            .then(strength => {
                                // update the strength
                                $ngRedux.dispatch(setStrength(strength));
                            });
                        // Update state with draft
                        $ngRedux.dispatch(updateDraft(draft));
                        // Update state with entry details
                        $ngRedux.dispatch(updateEntryDetails(entryDetails));
                    })
                    .catch(error => {
                        // Check to see if error sent is 403
                        if (error && error.status === 403) {
                            return $state.go('403');
                        }
                        // Check to see if error sent is 404
                        if (error && error.status === 404) {
                            return $state.go('404');
                        }
                    })
                    .finally(() => {
                        // Un-set the loading flag
                        $ngRedux.dispatch(toggleLoading());
                    });
            },
        ],
    },
    'entryEditor.edit': {
        url: '/edit/{entryId:string}',
        params: {
            entryId: {
                value: null,
                squash: true,
            },
        },
        onEnter: [
            '$ngRedux',
            '$pfBaseAssignmentUtilities',
            '$pfEntryAttachment',
            '$pfEntryDrafts',
            '$pfEntryDetails',
            '$pfEntryStrength',
            '$pfLayout',
            '$pfUser',
            '$state',
            '$transition$',
            'PF_ENTRY_CLASSIFICATIONS',
            (
                $ngRedux,
                $pfBaseAssignmentUtilities,
                $pfEntryAttachment,
                $pfEntryDrafts,
                $pfEntryDetails,
                $pfEntryStrength,
                $pfLayout,
                $pfUser,
                $state,
                $transition$,
                PF_ENTRY_CLASSIFICATIONS,
            ) => {
                // Set page title
                $pfLayout.title = 'Edit Project';

                // get current user
                const user = $pfUser.getUser();

                // Set the loading flag
                $ngRedux.dispatch(toggleLoading());

                // set the draftId so we know if a draft exists for this project
                let draftId = null;

                // fetch the entry, store assignment details in redux, then map to draft
                $pfEntryDetails
                    .getEntryDetailsById($transition$.params().entryId, {
                        assignment: true,
                    })
                    .then(entry => {
                        // set draftId
                        draftId = entry.draft_id;
                        // get entry classification
                        const entryClassification = $pfEntryDetails.getEntryClassification(
                            entry,
                        );
                        // get allowed file upload types
                        const allowedFileTypes = $pfEntryAttachment.getAllowedFileMimetypesByEntryClassification(
                            entryClassification,
                        );
                        // set entry classification
                        $ngRedux.dispatch(
                            setEntryClassification(entryClassification),
                        );
                        // set allowed file types
                        $ngRedux.dispatch(
                            setAllowedFileUploadTypes(allowedFileTypes),
                        );
                        // isAssignment applies to course assignments and pathway requirements
                        const isAssigment = $pfBaseAssignmentUtilities.isAssignment(
                            entry,
                        );
                        /**
                         * An entry with assignment data is not considered an assigment unless it is in a submittable state
                         */
                        if (!_.isEmpty(entry.assignment)) {
                            $ngRedux.dispatch(
                                updateAssignmentDetails(entry.assignment),
                            );
                        }
                        /**
                         * Set draft as assignment (submittable state is checked while getting entry classification)
                         * and set redirect for assignments
                         */
                        if (isAssigment) {
                            // Update state if this is this an assigment
                            $ngRedux.dispatch(setStatusIsAssignment());
                            // reroute by classification
                            if (
                                entryClassification ===
                                PF_ENTRY_CLASSIFICATIONS.assignment
                            ) {
                                const {
                                    course_slug: courseSlug,
                                    assignment_id: assignmentId,
                                } = entry.assignment;
                                // set redirect to assignment page
                                $ngRedux.dispatch(
                                    setStatusNextState('assignment', {
                                        courseSlug,
                                        assignmentId,
                                    }),
                                );
                            } else {
                                // set redirect to assignment page
                                $ngRedux.dispatch(
                                    setStatusNextState('requirement', {
                                        requirementId:
                                            entry.assignment.requirement_id,
                                        slug: entry.assignment.pathway_slug,
                                        username: entry.profile.username,
                                    }),
                                );
                            }
                        }
                        // Set the draft status to published
                        $ngRedux.dispatch(setStatusPublished());

                        return entry;
                    })
                    .then(entry => $pfEntryDrafts.mapEntryToDraft(entry))
                    .then(({ draft, entryDetails }) => {
                        if (draft.attachments) {
                            // Guarantee uniqueness
                            draft.attachments = $pfEntryAttachment.cleanAttachments(
                                draft.attachments,
                            );
                        }
                        // Update state with draft
                        $ngRedux.dispatch(updateDraft(draft));
                        // Update state with entry details for preview
                        $ngRedux.dispatch(updateEntryDetails(entryDetails));
                        // calculate the strength of the draft
                        $pfEntryStrength
                            .calculateDraftStrength(draft)
                            .then(strength => {
                                // update the strength
                                $ngRedux.dispatch(setStrength(strength));
                            });
                    })
                    .catch(error => {
                        // Check to see if error sent is 403
                        if (error && error.status === 403) {
                            return $state.go('403');
                        }
                        // Check to see if error sent is 404
                        if (error && error.status === 404) {
                            return $state.go('404');
                        }
                    })
                    .finally(() => {
                        // Un-set the loading flag
                        $ngRedux.dispatch(toggleLoading());
                    });
            },
        ],
    },
};
