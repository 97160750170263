export const SHARING_INBOUND_ROUTES = {
    'sharingInbound': {
        abstract: true,
        parent: 'layout.empty',
        resolve: {
            user: ['$pfUser', function ($pfUser) {
                let user = $pfUser.getUser();
                return user;
            }],
        },
        views: {
            'app@layout.empty': {
                // component: 'pfSharingInbound',
                template: `<pf-sharing-inbound></pf-sharing-inbound>`,
            },
        },
        data: {
            appClass: 'sharing-inbound',
            module: 'sharing-inbound',
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            // Set page title
            $pfLayout.title = 'Share on Portfolium';
        }],
        onExit: ['$pfHelpChat', ($pfHelpChat) => {
            // unhide help chat button
            $pfHelpChat.showButton();
        }],
    },
    'sharingInbound.badge': {
        url: '/share/badge?u&source',
        views: {
            'content': {
                // component: 'pfSharingInboundBadge',
                template: `
                    <pf-sharing-inbound-badge
                         flex
                         user="$resolve.user">
                    </pf-sharing-inbound-badge>
                `,
            },
        },
    },
};
