export const ACCOUNT_RETENTION_ROUTES = {
    'accountRetention': {
        abstract: true,
        parent: 'layout.standard',
        views: {
            'app@layout.standard': {
                template: `<pf-account-retention></pf-account-retention>`,
            },
        },
        data: {
            appClass: 'account-retention',
            module: 'account-retention',
        },
    },
    missYou: {
        parent: 'accountRetention',
        url: '/miss-you',
        resolve: {
            cookieData: [
                '$pfEnv',
                '$pfCookies',
                'PF_COOKIES',
                (
                    $pfEnv,
                    $pfCookies,
                    PF_COOKIES,
                ) => {
                    // get the cookie
                    let gdprCookie = $pfCookies.get(PF_COOKIES.GDPR_ACCOUNT_DELETED);

                    // then delete the cookie
                    $pfCookies.remove(PF_COOKIES.GDPR_ACCOUNT_DELETED, {
                        path: '/',
                        domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                    });

                    // convert to object
                    if (!_.isNil(gdprCookie) && _.isString(gdprCookie)) {
                        gdprCookie = JSON.parse(gdprCookie);
                    }

                    return gdprCookie;
                }
            ],
            // see if the user can enter this state
            init: [
                '$state',
                '$pfCookies',
                'cookieData',
                (
                    $state,
                    $pfCookies,
                    cookieData = {},
                ) => {
                    const { token, requested_at, will_delete_at } = cookieData;
                    // a user should only get here after deleting their account
                    // if we don't have the correct cookie data, redirect to 404
                    if (_.isNil(token) || _.isNil(requested_at) || _.isNil(will_delete_at)) {
                        // go to 404
                        return $state.go('404');
                    }
                }
            ],
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
                // Set page title
                $pfLayout.title = 'We\'ll miss you!';
        }],
        views: {
            '': {
                // component: 'pfAccountRetentionCancelDeletion'
                template: `
                    <pf-account-retention-cancel-deletion
                         token="{{ ::$resolve.cookieData.token }}"
                         requested_at="{{ ::$resolve.cookieData.requested_at }}"
                         will_delete_at="{{ ::$resolve.cookieData.will_delete_at }}">
                    </pf-account-retention-cancel-deletion>
                `,
            },
        },
    },
    welcomeBack: {
        parent: 'accountRetention',
        url: '/welcome-back',
        resolve: {
            init: [
                '$q',
                '$pfUser',
                '$state',
                (
                    $q,
                    $pfUser,
                    $state,
                ) => {
                    const currentUser = $pfUser.getUser();
                    // is user is authenticated, they should not be here
                    if (!_.isNil(currentUser) && !_.isNil(currentUser.username)) {
                        return $state.go('profile.about', { username: currentUser.username });
                    }
                    return;
                }
            ],
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
                // Set page title
                $pfLayout.title = 'Welcome back!';
        }],
        views: {
            '': {
                // component: 'pfAccountRetentionWelcomeBack'
                template: `<pf-account-retention-welcome-back></pf-account-retention-welcome-back>`,
            },
        },
    },
};
