import { ACCOUNT_RETENTION_ROUTES } from '../../../account-retention/account-retention.routing';
import { DISCOVER_ROUTES } from '../../../discover/discover.routing';
import { SEARCH_ROUTES } from '../../../search/search.routing';
import { ONBOARDING_ROUTES } from '../../../onboarding/onboarding.routing';
import { DASHBOARD_STATES } from '../../../dashboard/js/config/routes.config';
import { JOBS_ROUTES } from '../../../jobs/js/config/routes.config';
import { JOB_ROUTES } from '../../../job/job.routing';
import { ENTRY_DETAILS_ROUTES } from '../../../entry-details/entry-details.routing';
import { ENTRY_EDITOR_ROUTES } from '../../../entry-editor/entry-editor.routing';
import { SETTINGS_ROUTES } from '../../../settings/settings.routing';
import { INVITE_ROUTES } from '../../../invite/invite.routing';
import { PROFILE_ROUTES } from '../../../profile/js/config/routes.config';
import { TALENT_MATCH_ROUTES } from '../../../talent-match/layout/js/routes.config';
import { EMAIL_ROUTES } from '../../../email/js/config/routes.config';
import { COMPANY_ROUTES } from '../../../company/company.routing';
import { NETWORK_ROUTES } from '../../../network/network.routing';
import { MY_JOBS_ROUTES } from '../../../my-jobs/my-jobs.routing';
import { AUTH_PAGES_ROUTES } from '../../../auth-pages/auth-pages.routing';
import { CHALLENGES_ROUTES } from '../../../challenges/challenges.routing';
import { COURSE_ROUTES } from '../../../course/course.routing';
import { ASSIGNMENT_ROUTES } from '../../../assignment/assignment.routing';
import { MESSAGES_ROUTES } from '../../../messages/messages.routing';
import { SHARING_INBOUND_ROUTES } from '../../../sharing-inbound/sharing-inbound.routing';
import { PATHWAYS_ROUTES } from '../../../pathways/pathways.routing';
import { TASKS_ROUTES } from '../../../tasks/tasks.routing';

import LayoutEmptyTemplate from '../../template/layout-empty.html'
import LayoutStandardTemplate from '../../template/layout-standard.html'

export const routes = $stateProvider => {
    /**
     * Register a state with UI Router
     * @param  {Object} config State config options
     * @param  {String} name   State name
     */
    function registerState(config, name) {
        $stateProvider.state(name, config);
    }

    // Define the base layout states
    $stateProvider
        .state('layout', {
            abstract: true,
            sticky: true,
            dsr: true,
            views: {
                'layout@': {
                    // component: 'pfApp',
                    template: `<pf-app></pf-app>`,
                },
            },
        })
        .state('layout.empty', {
            abstract: true,
            template: LayoutEmptyTemplate,
        })
        .state('layout.standard', {
            abstract: true,
            template: LayoutStandardTemplate,
        });

    // Construct a single state tree from all the modules
    const moduleRoutes = _.assign(
        {},
        ACCOUNT_RETENTION_ROUTES,
        PATHWAYS_ROUTES,
        TASKS_ROUTES,
        ONBOARDING_ROUTES,
        DASHBOARD_STATES,
        DISCOVER_ROUTES,
        JOBS_ROUTES,
        ENTRY_DETAILS_ROUTES,
        COMPANY_ROUTES,
        ENTRY_EDITOR_ROUTES,
        TALENT_MATCH_ROUTES,
        JOB_ROUTES,
        CHALLENGES_ROUTES,
        COURSE_ROUTES,
        SETTINGS_ROUTES,
        INVITE_ROUTES,
        SEARCH_ROUTES,
        ASSIGNMENT_ROUTES,
        MESSAGES_ROUTES,
        AUTH_PAGES_ROUTES,
        PROFILE_ROUTES,
        EMAIL_ROUTES,
        NETWORK_ROUTES,
        MY_JOBS_ROUTES,
        SHARING_INBOUND_ROUTES,
    );

    // Register states
    _.map(moduleRoutes, registerState);
};

routes.$inject = ['$stateProvider'];
