/**
 * Check state data to determine whether user should be logged in to access state,
 * then handle the redirect case.
 *
 * To require authentication for a state, simply set the 'requireAuth' property to
 * true on the state config's 'data' object
 *
 * TODO: Make this more configurable so developers can add redirect logic,
 *       tiered levels of auth, etc.
 */
export const authCheckHook = ($transitions, $pfUser, $window, $state) => {
    $transitions.onError({}, trans => {
        const error = trans.error();
        // Check to see if error sent is 404
        if (error && error.status === 404) {
            $event.preventDefault();
            $state.go('404');
        }

        // Check to see if error sent is 403
        if (error && error.status === 403) {
            $event.preventDefault();
            $state.go('403');
        }
    });

    $transitions.onStart({}, trans => {
        const toState = trans.to();
        // Check to see if state is configured to require authentication
        if (toState.data && toState.data.requireAuth === true) {
            let user = $pfUser.getUser();

            if (!user) {
                // Block the state change for unauthed visitor
                // $event.preventDefault();
                // Redirect to default route
                return $window.location.assign('/');
            }
        }
    });
};

authCheckHook.$inject = ['$transitions', '$pfUser', '$window', '$state'];
