import {
    setChallenge,
    updateSubmissionCount,
    resetChallenge,
} from './challenge-detail/challenge-detail.actions';

export const CHALLENGES_ROUTES = {
    challenges: {
        abstract: true,
        parent: 'layout.standard',
        views: {
            'navbarMobile@layout.standard': {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "Challenges" | i18n }}
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'app@layout.standard': {
                template: `<pf-challenges></pf-challenges>`,
            },
        },
        data: {
            appClass: 'challenges',
            module: 'challenges',
        },
    },
    list: {
        parent: 'challenges',
        url: '/challenges',
        // component: 'pfChallengesList',
        template: `<pf-challenges-list></pf-challenges-list>`,
    },
    challenge: {
        parent: 'layout.standard',
        url: '/challenge/:slug',
        data: {
            appClass: 'challenges',
            module: 'challenges',
        },
        onEnter: [
            '$pfChallenges',
            '$transition$',
            '$ngRedux',
            ($pfChallenges, $transition$, $ngRedux) => {
                const { slug } = $transition$.params();
                // get challenge
                $pfChallenges.getChallengeBySlug(slug).then(challenge => {
                    // dispatch challenge
                    $ngRedux.dispatch(setChallenge(challenge));
                });
                // get current submissions
                $pfChallenges.getChallengeCountBySlug(slug).then(response => {
                    // dispatch entries count update
                    $ngRedux.dispatch(updateSubmissionCount(response.entries));
                });
            },
        ],
        views: {
            'navbarMobile@layout.standard': {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "Challenges" | i18n }}
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'app@layout.standard': {
                template: `
                <pf-challenges-detail></pf-challenges-detail>`,
            },
        },
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear application state so there's no lingering model when switching between companies
                $ngRedux.dispatch(resetChallenge());
            },
        ],
    },
};
