export const ONBOARDING_ROUTES = {
    onboarding: {
        abstract: true,
        parent: 'layout.empty',
        module: 'onboarding',
        resolve: {
            user: [
                '$pfOnboardingUser',
                function($pfOnboardingUser) {
                    let user = $pfOnboardingUser.getUser();
                    return user;
                },
            ],
            network: [
                '$pfUser',
                'user',
                function($pfUser, user) {
                    return $pfUser
                        .getNetworksByUsername(user.username, {
                            approved: false,
                        })
                        .then(networks => {
                            if (networks.length) {
                                // Return the first network
                                return networks[0];
                            }

                            return null;
                        });
                },
            ],
        },
        views: {
            'app@layout.empty': {
                // component: 'pfOnboarding',
                template: `<pf-onboarding></pf-onboarding>`,
            },
            'sidebar@onboarding': {
                // component: 'pfOnboardingSidebar',
                template: `
                    <pf-onboarding-sidebar
                         user="$resolve.user"
                         network="$resolve.network">
                    </pf-onboarding-sidebar>
                `,
            },
        },
        data: {
            appClass: 'onboarding',
            module: 'onboarding',
        },
        onEnter: [
            '$pfLayout',
            $pfLayout => {
                // Set page title
                $pfLayout.title = 'Welcome to Portfolium';
            },
        ],
    },
    'onboarding.userInfo': {
        views: {
            content: {
                // component: 'pfOnboardingUserInfo',
                template: `
                    <pf-onboarding-user-info
                         user="$resolve.user"
                         network="$resolve.network">
                    </pf-onboarding-user-info>
                `,
            },
        },
    },
    'onboarding.profile': {
        url: '/welcome',
        resolve: {
            isOnboarded: [
                'user',
                '$pfOnboarding',
                '$pfUser',
                '$q',
                '$timeout',
                function(user, $pfOnboarding, $pfUser, $q, $timeout) {
                    const currentUser = $pfUser.getUser();
                    const isOnboarded = currentUser.isOnboarded();
                    if (isOnboarded) {
                        // check for lti visitor
                        const isFromLti = $pfOnboarding.getFromLtiFlag();
                        // check for onboarding bypass
                        const skipOnboarding = $pfOnboarding.getOnboardingBypassFlag();
                        // need this to redirect from resolve (before render)
                        $timeout(() => {
                            $pfOnboarding.handleWelcomeRedirect(
                                user,
                                isFromLti,
                                skipOnboarding,
                            );
                        });
                        // and then reject the promise
                        return $q.resolve();
                    }
                    return;
                },
            ],
        },
        views: {
            content: {
                // component: 'pfOnboardingProfile',
                template: `
                    <pf-onboarding-profile
                         user="$resolve.user"
                         network="$resolve.network">
                    </pf-onboarding-profile>
                `,
            },
        },
    },
    'onboarding.verify': {
        url: '/verify',
        params: {
            isInitialStep: {
                value: false,
                squash: true,
            },
        },
        resolve: {
            isInitialStep: [
                '$stateParams',
                function($stateParams) {
                    // show first verification screen
                    const { isInitialStep } = $stateParams;
                    return isInitialStep;
                },
            ],
        },
        views: {
            content: {
                // component: 'pfOnboardingVerify',
                template: `
                    <pf-onboarding-verify
                         user="$resolve.user"
                         network="$resolve.network"
                         is-initial-step="$resolve.isInitialStep">
                    </pf-onboarding-verify>
                `,
            },
        },
    },
};
