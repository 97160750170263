export const SETTINGS_ROUTES = {
    'settings': {
        parent: 'layout.standard',
        url: '/settings',
        views: {
            'subnav': {
                // component: 'pfSettingsSubnav',
                template: `<pf-settings-subnav></pf-settings-subnav>`,
            },
            'navbarMobile': {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "Settings" | i18n }}
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'app': {
                template: `<pf-settings></pf-settings>`,
            },
        },
        dynamic: true,
        deepStateRedirect: {
            default: 'settingsProfile',
        },
        data: {
            appClass: 'settings',
            module: 'settings',
        },
    },
    'settingsProfile': {
        parent: 'settings',
        url: '/profile',
        // component: 'pfSettingsProfile',
        template: `<pf-settings-profile></pf-settings-profile>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Profile Settings`;
            }
        ],
    },
    'settingsEmail': {
        parent: 'settings',
        url: '/email',
        // component: 'pfSettingsEmail',
        template: `<pf-settings-email></pf-settings-email>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Email Settings`;
            }
        ],
    },
    'settingsImportData': {
        parent: 'settings',
        url: '/import-data',
        data: {
            trackPageName: 'Data Import',
        },
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Import Data`;
            }
        ],
        // component: 'pfSettingsImportData',
        template: `<pf-settings-import-data></pf-settings-import-data>`,
    },
    'settingsLocation': {
        parent: 'settings',
        url: '/address',
        // component: 'pfSettingsLocation',
        template: `<pf-settings-location></pf-settings-location>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Location Settings`;
            }
        ],
    },
    'settingsSocial': {
        parent: 'settings',
        url: '/social',
        // component: 'pfSettingsSocial',
        template: `<pf-settings-social></pf-settings-social>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Link Your Social Accounts`;
            }
        ],
    },
    'settingsNotifications': {
        parent: 'settings',
        url: '/notifications',
        // component: 'pfSettingsNotifications',
        template: `<pf-settings-notifications></pf-settings-notifications>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Notification Settings`;
            }
        ],
    },
    'settingsClearances': {
        parent: 'settings',
        url: '/clearances',
        // component: 'pfSettingsClearances',
        template: `<pf-settings-clearances></pf-settings-clearances>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Security Clearances & Credentials`;
            }
        ],
    },
    'settingsPassword': {
        parent: 'settings',
        url: '/password',
        // component: 'pfSettingsPassword',
        template: `<pf-settings-password></pf-settings-password>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Update Your Password`;
            }
        ],
    },
    'settingsBlockedAccounts': {
        parent: 'settings',
        url: '/blocked-accounts',
        // component: 'pfSettingsBlockedAccounts',
        template: `<pf-settings-blocked-accounts></pf-settings-blocked-accounts>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Blocked Accounts`;
            }
        ],
    },
    'settingsPrivacy': {
        parent: 'settings',
        url: '/privacy?download',
        // component: 'pfSettingsPrivacy',
        template: `<pf-settings-privacy></pf-settings-privacy>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Privacy Settings`;
            }
        ],
    },
    'settingsPrivacyDisable': {
        parent: 'layout.standard',
        url: '/settings/privacy/disable',
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Disable Account`;
            }
        ],
        views: {
            'navbarMobile@layout.standard': {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "Disable Account" | i18n }}
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'app@layout.standard': {
                // component: 'pfSettingsAccountDisable',
                template: `<pf-settings-account-disable should-delete="false"></pf-settings-account-disable>`,
            },
        },
        data: {
            appClass: 'settings',
            module: 'settings',
        },
    },
    'settingsPrivacyDelete': {
        parent: 'layout.standard',
        url: '/settings/privacy/delete',
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `Delete Account`;
            }
        ],
        views: {
            'navbarMobile@layout.standard': {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "Delete Account" | i18n }}
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'app@layout.standard': {
                // component: 'pfSettingsAccountDisable',
                template: `<pf-settings-account-disable should-delete="true"></pf-settings-account-disable>`,
            },
        },
        data: {
            appClass: 'settings',
            module: 'settings',
        },
    },
    'settingsTalentMatch': {
        parent: 'settings',
        url: '/talent-match',
        // component: 'pfSettingsTalentMatch',
        template: `<pf-settings-talent-match></pf-settings-talent-match>`,
        onEnter: [
            '$pfLayout',($pfLayout) => {
                // Set page title
                $pfLayout.title = `TalentMatch Account Settings`;
            }
        ],
    },
};
