import { updateAbouts, resetAbouts } from '../action/abouts';
import { updateAboutSectionOrder } from '../action/about-sections';
import { updateProfileUser, resetProfileUser } from '../action/profile-user';
import {
    updateProfileHighlights,
    resetProfileHighlights,
} from '../action/profile-highlights';
import { resetUserEntries } from '../../../core/reducers/user-entries.action';

export const PROFILE_ROUTES = {
    profile: {
        abstract: true,
        parent: 'layout.standard',
        url: '/:username',
        module: 'profile',
        resolve: {
            authedUser: [
                '$pfUser',
                $pfUser => {
                    return $pfUser.getUser();
                },
            ],
            isEdit: [
                '$pfAuth',
                '$transition$',
                ($pfAuth, $transition$) => {
                    return $pfAuth.isAuthenticatedUserByUsername(
                        $transition$.params().username,
                    );
                },
            ],
        },
        views: {
            'navbarMobile@layout.standard': {
                template: `
                    <pf-navbar-mobile>
                        <navbar-aux-button>
                            <pf-profile-mobile-menu></pf-profile-mobile-menu>
                        <navbar-aux-button>
                    </pf-navbar-mobile>
                `,
            },
            'app@layout.standard': {
                // component: 'pfProfile',
                template: `
                    <pf-profile
                         is-edit="$resolve.isEdit">
                    </pf-profile>
                `,
            },
            'header@profile': {
                // component: 'pfProfileHeader',
                template: `
                    <pf-profile-header
                         user="$ctrl.profileUser"
                         is-edit="$ctrl.isEdit">
                    </pf-profile-header>
                `,
            },
            'sidebar@profile': {
                // component: 'pfProfileSidebar',
                template: `
                    <pf-profile-sidebar
                         user="$ctrl.profileUser"
                         abouts="$ctrl.abouts"
                         is-edit="$ctrl.isEdit">
                    </pf-profile-sidebar>
                `,
            },
        },
        onEnter: [
            '$pfGameEngine',
            '$pfAuth',
            '$pfProfile',
            '$pfUserAbout',
            '$transition$',
            '$pfSectionName',
            '$pfLayout',
            '$ngRedux',
            '$state',
            (
                $pfGameEngine,
                $pfAuth,
                $pfProfile,
                $pfUserAbout,
                $transition$,
                $pfSectionName,
                $pfLayout,
                $ngRedux,
                $state,
            ) => {
                const { username } = $transition$.params();
                const isEdit = $pfAuth.isAuthenticatedUserByUsername(username);

                // update highlights state
                $ngRedux.dispatch(
                    updateProfileHighlights($state.params.keywords),
                );

                // Fetch user data
                $pfProfile.getUserByUsername(username).then(
                    user => {
                        // Update application state
                        $ngRedux.dispatch(updateProfileUser(user));

                        // Set page title
                        $pfLayout.title = `${user.firstname} ${user.lastname}`;

                        // Set section name to display user's name in the navbar for TalentMatch
                        $pfSectionName.setName(
                            `${user.firstname} ${user.lastname}`,
                        );
                    },
                    response => {
                        // This is a blocked profile, redirect
                        if (response.status === 405) {
                            return $state.go('blocked', {
                                username,
                                blockedUser: response.data.blocked_user,
                                blockedDate: response.data.blocked_date,
                            });
                        }

                        if (response.status === 403) {
                            return $state.go('private', { username: username });
                        }

                        return $state.go('404');
                    },
                );

                // Fetch about data
                $pfUserAbout
                    .getAboutByUsername(username)
                    .then(about => {
                        // Update application state
                        $ngRedux.dispatch(updateAbouts(about));
                        $ngRedux.dispatch(updateAboutSectionOrder(about.order));
                    })
                    .catch(() => {});

                if (isEdit) {
                    // Get game engine suggestions if user is viewing their own profile
                    $pfGameEngine.getResults();
                }
            },
        ],
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear application state so there's no lingering model when switching between profiles
                $ngRedux.dispatch(resetProfileUser());
                $ngRedux.dispatch(resetAbouts());
                $ngRedux.dispatch(resetProfileHighlights());
            },
        ],
        data: {
            appClass: 'profile',
            module: 'profile',
        },
    },
    'profile.about': {
        url: '?section&uploadresume&addentry&addbadge&selectedbadge',
        views: {
            'content@profile': {
                // component: 'pfProfileAbout',
                template: `
                    <main>
                        <pf-profile-about
                            user="$ctrl.profileUser"
                            abouts="$ctrl.abouts"
                            about-sections="$ctrl.aboutSections"
                            is-edit="$ctrl.isEdit">
                        </pf-profile-about>
                    </main>
                `,
            },
        },
        params: {
            section: {
                value: null,
                squash: true,
            },
            highlights: {
                array: true,
            },
            // this value  needs to be a string that matches a panel config name in 'register-panels.config.js'
            // see onEnter below for more info...
            openPanelName: {
                value: null,
            },
        },
        onEnter: [
            '$transition$',
            '$pfProfileAboutPanel',
            ($transition$, $pfProfileAboutPanel) => {
                // open an "about panel" if the openPanelName param was set
                // for example:
                // ui-sref="profile.about(::{username: 'adam', openPanelName: 'education'})"
                if ($transition$.params().openPanelName) {
                    $pfProfileAboutPanel
                        .addAbout($transition$.params().openPanelName)
                        .then(null, err => {
                            throw new Error(
                                `[profile.about.onEnter] Error openPanelName -> ${
                                    $transition$.params().openPanelName
                                }: ${err}`,
                            );
                        });
                }
            },
        ],
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear the cached user entries list
                $ngRedux.dispatch(resetUserEntries());
            },
        ],
    },
    'profile.portfolio': {
        url: '/portfolio?mode&sort&ownership&skill_id&bucket_id',
        views: {
            'content@profile': {
                template: `
                    <main>
                        <pf-profile-portfolio
                            user="$ctrl.profileUser"
                            is-edit="$ctrl.isEdit">
                        </pf-profile-portfolio>
                    </main>
                `,
            },
        },
        params: {
            mode: {
                value: 'grid',
                squash: true,
            },
            ownership: {
                value: null,
                squash: true,
            },
        },
    },
    'profile.drafts': {
        url: '/drafts',
        views: {
            'content@profile': {
                // component: 'pfProfileDrafts',
                template: `
                    <main>
                        <pf-profile-drafts
                            is-edit="$ctrl.isEdit">
                        </pf-profile-drafts>
                    </main>
                `,
            },
        },
    },
    'profile.connections': {
        url: '/connections?sort&type',
        views: {
            'content@profile': {
                // component: 'pfProfileConnections',
                template: `
                    <main>
                        <pf-profile-connections
                            user="$ctrl.profileUser"
                            is-edit="$ctrl.isEdit">
                        </pf-profile-connections>
                    </main>
                `,
            },
        },
        params: {
            type: {
                value: null,
                squash: true,
            },
        },
    },
};
