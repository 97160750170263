export const JOB_ROUTES = {
    'job': {
        parent: 'layout.standard',
        url: '/job/:slug?src&traffic_source',
        resolve: {
            jobSlug: ['$stateParams', $stateParams => $stateParams.slug],
            init: [
                '$state',
                '$pfEnv',
                ($state, $pfEnv) => {
                    if (!$pfEnv.jobsEnabled()) {
                        return $state.go('404');
                    }
                }
            ],
        },
        views: {
            'app@layout.standard': {
                template: `<pf-job job-slug="$resolve.jobSlug"></pf-job>`,
            },
        },
        data: {
            appClass: 'job',
            module: 'job',
        },
        params: {
            traffic_source: null,
        },
    },
};
