export class AppController {
    constructor($transitions, $timeout, $mdMedia, $ngRedux, $pfSubscription, $pfFeatures) {
        this.$transitions = $transitions;
        this.$timeout = $timeout;
        this.$mdMedia = $mdMedia;
        this.$ngRedux = $ngRedux;
        this.$pfSubscription = $pfSubscription;
        this.loading = false;
        this.$pfFeatures = $pfFeatures;
    }

    $onInit() {
        this.$transitions.onStart({}, () => {
            this.setLoading.bind(this);
        });
        this._unsubscribe = this.$ngRedux.connect(this._mapStateToThis)(this);
        this.$pfFeatures.init().then();
    }

    $onDestroy() {
        this._unsubscribe();
    }

    get isSubscriber() {
        return this.$pfSubscription.isSubscriber();
    }

    /**
     * If a state change takes longer than the delay length (500ms), show a
     * loading progress bar at the top of the screen
     */
    setLoading(event, toState, toParams, fromState, fromParams) {
        // allow states to diasable the loading bar
        if (_.isObject(toState.data) && toState.data.disableLoadingBar) {
            return;
        }

        // we will not get a $stateChangeSuccess event if preventDefault()
        // was called on the state change
        this.$timeout(() => {
            if (event.defaultPrevented) {
                // Cancel the timeout, if pendimg
                this.$timeout.cancel(delayed);
                // Unset the loading flag
                this.loading = false;
                // Unbind the listeners
                disableListener();
                disableErrorListener();
            }
        });

        const delayLength = 500;

        // Wait for delay before enabling the loader
        const delayed = this.$timeout(() => {
            // Set the loading flag
            this.loading = true;
        }, delayLength);

        // Add a state change success listener to handle disabling the loader
        const disableListener = this.$transitions.onSuccess({}, () => {
            // Cancel the timeout, if pendimg
            this.$timeout.cancel(delayed);
            // Unset the loading flag
            this.loading = false;
            // Unbind the listener
            disableListener();
        });

        // Add a state change error listener to handle disabling the loader
        const disableErrorListener = this.$transitions.onError({}, () => {
            // Cancel the timeout, if pendimg
            this.$timeout.cancel(delayed);
            // Unset the loading flag
            this.loading = false;
            // Unbind the listener
            disableErrorListener();
        });
    }

    _mapStateToThis(state) {
        return {
            profileUser: state.profile.profileUser,
        };
    }
}

AppController.$inject = [
    '$transitions',
    '$timeout',
    '$mdMedia',
    '$ngRedux',
    '$pfSubscription',
    '$pfFeatures',
];
