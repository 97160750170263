import {
    fetchData,
    onCoursesFetchSuccess,
    onFetchDataSuccess,
    onPathwaysFetchSuccess,
} from './tasks.actions';

export const TASKS_ROUTES = {
    tasks: {
        parent: 'layout.standard',
        url: '/tasks',
        views: {
            'app@layout.standard': {
                template: `<pf-tasks></pf-tasks>`,
            },
            'navbarMobile@layout.standard': {
                template: `
                    <pf-navbar-mobile>
                        <navbar-title i18n>Tasks</navbar-title>
                    </pf-navbar-mobile>
                `,
            },
        },
        data: {
            appClass: 'tasks',
            module: 'tasks',
            trackPageName: 'Tasks',
        },
        onEnter: [
            '$pfLayout',
            '$pfTasks',
            '$ngRedux',
            '$q',
            ($pfLayout, $pfTasks, $ngRedux, $q) => {
                // Set page default title
                $pfLayout.title = 'Tasks';
                // dispatch fetchData to set loader
                $ngRedux.dispatch(fetchData());

                // user assigned pathways promise
                let pathways = $pfTasks.getUserPathways();
                // user assigned courses promise
                let courses = $pfTasks.getUserCourses();

                $q.all({
                    pathways,
                    courses,
                }).then(data => {
                    $ngRedux.dispatch(onPathwaysFetchSuccess(data.pathways));
                    $ngRedux.dispatch(onCoursesFetchSuccess(data.courses));
                    $ngRedux.dispatch(onFetchDataSuccess());
                });
            },
        ],
    },
};
