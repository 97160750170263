import { networkRequest, updateNetwork, resetNetwork } from './network.actions';

export const NETWORK_ROUTES = {
    network: {
        parent: 'layout.standard',
        abstract: true,
        url: '/network/:network',
        views: {
            'app@layout.standard': {
                template: `<pf-network></pf-network>`,
            },
        },
        onEnter: [
            '$state',
            '$ngRedux',
            '$transition$',
            '$pfNetwork',
            '$pfLayout',
            ($state, $ngRedux, $transition$, $pfNetwork, $pfLayout) => {
                const { network } = $transition$.params();
                $ngRedux.dispatch(networkRequest());
                $pfNetwork.getNetworkDetailsBySlug(network).then(
                    network => {
                        $ngRedux.dispatch(updateNetwork(network));
                        // Set page title
                        $pfLayout.title = `${network.name} Network`;
                    },
                    () => {
                        return $state.go('404');
                    },
                );
            },
        ],
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear application state so there's
                // no lingering model when switching between networks
                $ngRedux.dispatch(resetNetwork());
            },
        ],
        data: {
            appClass: 'network',
            module: 'network',
        },
    },
    networkEntries: {
        parent: 'network',
        url: '?sort&category',
        dynamic: true,
        // component: 'pfNetworkEntries',
        template: `<pf-network-entries categories="$ctrl.categories"></pf-network-entries>`,
    },
    networkMembers: {
        parent: 'network',
        url: '/members?sort&types&q',
        dynamic: true,
        params: {
            types: {
                array: true,
            },
        },
        // component: 'pfNetworkPeople',
        template: `<pf-network-members></pf-network-members>`,
    },
    networkAbout: {
        parent: 'network',
        url: '/about?sort',
        dynamic: true,
        // component: 'pfNetworkCompanies',
        template: `<pf-network-about></pf-network-about>`,
    },
    networkJobs: {
        parent: 'network',
        url: '/jobs?sort',
        dynamic: true,
        // component: 'pfNetworkCompanies',
        template: `<pf-network-jobs></pf-network-jobs>`,
    },
};
