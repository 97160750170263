/**
 * Watch the layout service for changes to the page title, then update the title
 * meta tag on the layout
 *
 * TODO: Eliminate this directive when layout is no longer in PHP
 *       (ng-bind title tag directly)
 */
export const pageTitleDirective = ($pfLayout) => {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            scope.$watch(() => $pfLayout.title, (title) => {
                if (_.isString(title) && title.length) {
                    elem.text(title);
                }
            });
        },
    };
};

pageTitleDirective.$inject = ['$pfLayout'];
