export const INVITE_ROUTES = {
    'invite': {
        parent: 'layout.standard',
        url: '/invite',
        views: {
            'app@layout.standard': {
                template: `<pf-invite></pf-invite>`,
            },
        },
        data: {
            appClass: 'invite',
            module: 'invite',
        },
    },
};
