import {
    updateCurrentThread,
    resetCurrentThread,
} from './thread/thread.actions';

export const MESSAGES_ROUTES = {
    messages: {
        parent: 'layout.standard',
        url: '/messages',
        views: {
            'navbarMobile@layout.standard': {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "Messages" | i18n }}
                        </navbar-title>
                        <navbar-aux-button>
                            <pf-messages-mobile-menu></pf-messages-mobile-menu>
                        <navbar-aux-button>
                    </pf-navbar-mobile>
                `,
            },
            'app@layout.standard': {
                template: `<pf-messages></pf-messages>`,
            },
        },
        onEnter: [
            '$pfLayout',
            $pfLayout => {
                // Set page title
                $pfLayout.title = `Inbox`;
            },
        ],
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear application state so there's no lingering model when switching between threads
                $ngRedux.dispatch(resetCurrentThread());
            },
        ],
        data: {
            appClass: 'messages',
            module: 'messages',
        },
    },
    thread: {
        parent: 'messages',
        url: '/:id',
        resolve: {
            threadSuccess: [
                '$pfLayout',
                '$stateParams',
                '$ngRedux',
                '$pfMessages',
                ($pfLayout, $stateParams, $ngRedux, $pfMessages) => {
                    // get thread id
                    const { id } = $stateParams;
                    // Get thread using id
                    return $pfMessages.getThread(id, 'latest').then(thread => {
                        // construct the new sidebar item
                        const readThread = _.assign({}, thread, {
                            read_at: 'read',
                        });
                        // update model with the new thread
                        $ngRedux.dispatch(updateCurrentThread(readThread));
                    });
                },
            ],
        },
        resolvePolicy: { async: 'NOWAIT' },
        // component: 'pfThread',
        template: `
            <pf-thread
             on-reply="$ctrl.onReplyComposer(message)"
             reply-loading=$ctrl.replyLoading>
            </pf-thread>`,
    },
};
