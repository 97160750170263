/**
 * Add a state change hook to fire a virtual pageview event to Google Analytics.
 * The path used for the pageview can be configured in the state definition object.
 */
export const virtualPageViewHook = ($transitions, $window, $location) => {
    $transitions.onSuccess({}, trans => {
        const toState = trans.to();
        const fromState = trans.from();

        // Make sure Google Analytics library is loaded
        if (!$window.ga) {
            return;
        }

        /**
         * Ignore the first page load (captured by GA by default)
         *
         * TODO: Disable default behaviour and use virtual pageviews for everything
         *       when switching to full single page app. Currently, PHP handles the
         *       logic for using a different path on page load.
         */
        if (!fromState.name || !fromState.name.length) {
            return;
        }

        // Make sure virtual pageview tracking isn't disabled for destination state
        if (toState.data && toState.data.virtualPageview === false) {
            return;
        }

        // Use current URI as default pageview path
        let pageviewPath = $location.path();
        // Grab the optional config object from the state data
        let config = (toState.data && toState.data.virtualPageview) || {};

        if (config.path) {
            pageviewPath = config.path;
        }

        // Fire a virtual pageview event to Google Analytics
        $window.ga('send', 'pageview', pageviewPath);
    });
};

virtualPageViewHook.$inject = ['$transitions', '$window', '$location'];
