import ContentTemplate from '../../template/content.html';

export const TALENT_MATCH_SEARCH_ROUTES = {
    'talentMatch.search': {
        url: '/search/talent?keywords&sort&offset&firstname&lastname&company&athletics&clearances&volunteer&accomplishments&skill&gpa&entry_title&entry_description&category&tag&spotlighted&expert&location&school&school_state&school_region&region&major&club&enrollment&badge&issuer&degree&has_badges&follows_my_company&batch_key&batch_type&target_id&search_title',
        params: {
            location: {
                array: true,
            },
            school: {
                array: true,
            },
            school_state: {
                array: true,
            },
            school_region: {
                array: true,
            },
            major: {
                array: true,
            },
            region: {
                array: true,
            },
            club: {
                array: true,
            },
            enrollment: {
                array: true,
            },
            badge: {
                array: true,
            },
            issuer: {
                array: true,
            },
            degree: {
                array: true,
            },
        },
        views: {
            '@talentMatch': {
                // component: 'pfTalentMatchSearch',
                template: `<pf-talent-match-search></pf-talent-match-search>`,
            },
            'facetSidenav@talentMatch.search': {
                // component: 'pfTalentMatchSearchFacetSidenav',
                template: `<pf-talent-match-search-facet-sidenav></pf-talent-match-search-facet-sidenav>`,
            },
            'advancedSearchSidenav@talentMatch.search': {
                // component: 'pfTalentMatchAdvancedSearchSidenav',
                template: `<pf-talent-match-advanced-search-sidenav></pf-talent-match-advanced-search-sidenav>`,
            },
            'subnav@talentMatch.search': {
                // component: 'pfTalentMatchSearchSubnav',
                template: `<pf-talent-match-search-subnav></pf-talent-match-search-subnav>`,
            },
            'content@talentMatch.search': {
                template: ContentTemplate,
            },
        },
        dynamic: true,
        data: {
            'sectionName': 'Search',
            'disableLoadingBar': true,
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Search - TalentMatch';
        }],
    },
};
