import HeaderTemplate from '../../template/companies/header.html'

export const JOBS_ROUTES = {
    'jobs': {
        resolve: {
            init: [
                '$state',
                '$pfEnv',
                ($state, $pfEnv) => {
                    if (!$pfEnv.jobsEnabled()) {
                        return $state.go('404');
                    }
                }
            ],
        },
        abstract: true,
        parent: 'layout.standard',
        module: 'jobs',
        views: {
            'navbarMobile@layout.standard': {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "Jobs" | i18n }}
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'app@layout.standard': {
                // component: 'pfJobs',
                template: `<pf-jobs></pf-jobs>`,
            },
        },
        data: {
            appClass: 'jobs',
            module: 'jobs',
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            // Set page title
            $pfLayout.title = 'Jobs And Internships At Top Companies';
        }],
    },
    'jobs.jobs': {
        url: '/jobs?q&s&company&industry&function&locale&type&limit&offset&distance&lat&lon&anywhere',
        views: {
            'header@jobs.jobs': {
                // component: 'pfJobsHeader',
                template: `<pf-jobs-header></pf-jobs-header>`,
            },
            'sidebar@jobs.jobs': {
                // component: 'pfJobsSidebar',
                template: `<pf-jobs-sidebar></pf-jobs-sidebar>`,
            },
            'content': {
                // component: 'pfJobsContent',
                template: `<pf-jobs-content></pf-jobs-content>`,
            },
        },
    },
    'jobs.companies': {
        url: '/companies?sort',
        views: {
            'header@jobs.companies': {
                template: HeaderTemplate,
            },
            'filters': {
                // component: 'pfCompaniesFilters',
                template: `<pf-companies-filters></pf-companies-filters>`,
            },
            'content': {
                // component: 'pfCompaniesContent',
                template: `<pf-companies-content></pf-companies-content>`,
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            // Set page title
            $pfLayout.title = 'Jobs And Internships At Top Companies';
        }],
    },
};
