import $script from 'scriptjs';

// use $resolve.currentCompany from the main TM route in layout
export const TALENT_MATCH_SETTINGS_ROUTES = {
    'talentMatch.tmSettings': {
        abstract: true,
        views: {
            '@talentMatch': {
                // component: 'pfTalentMatchSettings
                template: `
                    <pf-talent-match-settings
                         current-company="$resolve.currentCompany">
                    </pf-talent-match-settings>
                `,
            },
            'sidenav@talentMatch.tmSettings': {
                // component: 'pfTalentMatchSettingsSidenav
                template: `
                    <pf-talent-match-settings-sidenav
                         current-company="$resolve.currentCompany">
                    </pf-talent-match-settings-sidenav>
                `,
            },
            'content@talentMatch.tmSettings': {
                template: '<div ui-view></div>',
            },
        },
    },
    'talentMatch.tmSettings.editCompany': {
        url: '/company/:company_id/edit',
        data: {
            'sectionName': 'Edit Company',
        },
        views: {
            'content@talentMatch.tmSettings': {
                // component: 'pfTalentMatchSettingsCompany
                template: `
                    <pf-talent-match-settings-company
                         current-company="$resolve.currentCompany">
                    </pf-talent-match-settings-company>
                `,
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Edit Company - TalentMatch';
        }],
    },
    'talentMatch.tmSettings.templates': {
        url: '/templates',
        data: {
            'sectionName': 'Message Templates',
        },
        views: {
            'content@talentMatch.tmSettings': {
                // component: 'pfTalentMatchTemplates',
                template: `<pf-talent-match-settings-templates></pf-talent-match-settings-templates>`,
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Message Templates - TalentMatch';
        }],
    },
    'talentMatch.tmSettings.templates.edit': {
        url: '/edit/{id:int}',
        data: {
            'sectionName': 'Edit Message Template',
        },
        views: {
            'content@talentMatch.tmSettings': {
                // component: 'pfTalentMatchEditTemplate',
                template: `<pf-talent-match-settings-edit-template></pf-talent-match-settings-edit-template>`,
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Edit Message Template - TalentMatch';
        }],
    },
    'talentMatch.tmSettings.widget': {
        url: '/company/:company_id/widget',
        data: {
            'sectionName': 'Company Widget',
        },
        views: {
            'content@talentMatch.tmSettings': {
                // component: 'pfTalentMatchSettingsWidget
                template: `<pf-talent-match-settings-widget
                     current-company="$resolve.currentCompany">
                </pf-talent-match-settings-widget>`,
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Company Widget - TalentMatch';
        }],
    },
};
