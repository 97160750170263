import angular from 'angular';

import app from './app';

import { checkVersion } from './js/config/check-version.config';
import { routes } from './js/config/routes.config';
import { authCheckHook } from './js/config/hook/auth-check-hook.config';
import { bodyClassHook } from './js/config/hook/body-class-hook.config';
import { helpChatHook } from './js/config/hook/help-chat-hook.config';
import { lazyLoadComponentsHook } from './js/config/hook/lazy-load-components-hook.config';
import { virtualPageViewHook } from './js/config/hook/virtual-pageview-hook.config';
import { pageTitleDirective } from './js/directive/page-title.directive';
import { AppComponent } from './js/component/app.component';
import { LayoutStandardComponent } from './js/component/layout-standard.component';
import { VersionService } from './js/service/version.service';

const MODULE_NAME = 'portfolium.spa';

(function() {
    angular
        .module(MODULE_NAME, [
            /**
             * External Dependencies
             */
            /**
             * Internal Dependencies
             */
            app,
        ])
        .config(routes)
        .run(checkVersion)
        .run(authCheckHook)
        .run(bodyClassHook)
        .run(lazyLoadComponentsHook)
        .run(virtualPageViewHook)
        .directive('pfPageTitle', pageTitleDirective)
        .component('pfApp', AppComponent)
        .component('pfLayoutStandard', LayoutStandardComponent)
        .service('$pfVersion', VersionService)
        .run(helpChatHook);

    // Bootstrap the app
    angular.bootstrap(document, [MODULE_NAME], { strictDi: true });
})();
