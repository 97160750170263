import DashboardTemplate from '../../template/dashboard.html';

export const TALENT_MATCH_DASHBOARD_ROUTES = {
    'talentMatch.dashboard': {
        url: '/dashboard',
        data: {
            'sectionName': 'Dashboard',
        },
        views: {
            '@talentMatch': {
                template: DashboardTemplate,
            },
            'sidebar@talentMatch.dashboard': {
                // component: 'pfTalentMatchDashboardSidebar',
                template: `
                    <pf-talent-match-dashboard-sidebar
                         current-company="$resolve.currentCompany">
                    </pf-talent-match-dashboard-sidebar>
                `,
            },
            'content@talentMatch.dashboard': {
                // component: 'pfTalentMatchDashboardContent',
                template: `
                    <pf-talent-match-dashboard-content
                         current-company="$resolve.currentCompany">
                    </pf-talent-match-dashboard-content>
                `,
            },
            'jobMatches@talentMatch.dashboard': {
                // component: 'pfTalentMatchJobMatches',
                template: `<pf-talent-match-job-matches></pf-talent-match-job-matches>`,
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Dashboard - TalentMatch';
        }],
    },
};
