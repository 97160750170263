/**
 * Poll the server to determine if a new version of the app is available
 */
export const checkVersion = ($interval, $pfVersion, $pfEnv) => {
    if ($pfEnv.isDev) {
        // Only check for updates in QA or Production
        return;
    }

    const delay = 1000 * 60 * 5; // 5 minutes
    const tick = () => {
        $pfVersion
            .checkForNewVersion()
            .then(isUpdateAvailable => {
                // Show toast if new version is available
                if (isUpdateAvailable) {
                    $pfVersion.showUpdateToast();
                }
            })
            .catch(err => {
                if ($pfEnv.isLocal) {
                    return console.error(err);
                }

                throw new Error(err);
            });
    };

    // Check for asset updates every 5 minutes
    $interval(tick, delay);
};

checkVersion.$inject = ['$interval', '$pfVersion', '$pfEnv'];
