export const lazyLoadComponentsHook = ($state, $transitions, $pfLazyLoad) => {
    /**
     * Determine whether the state has a module that needs to be loaded
     * @param  {Object}         state UI Router state object
     * @return {Boolean|String}       Module name or false if state has no lazy module
     */
    function getLazyModule(state) {
        let module = state.data && state.data.module;

        if (module) {
            return module;
        }

        return false;
    }

    /**
     * Register a global hook to lazy load modules based on UI-Router state
     * definition. If state data has a "module" property, load that module before
     * completing the state change.
     */
    $transitions.onStart({}, trans => {
        const toState = trans.to();
        const toParams = trans.params('to');
        const lazyModule = getLazyModule(toState);

        if (!lazyModule) {
            // State has no module to lazy load
            return;
        }

        if ($pfLazyLoad.isModuleLoaded(lazyModule)) {
            // Module is already loaded
            return;
        }

        // Block the state change
        // $event.preventDefault();

        // Load the module
        return $pfLazyLoad.load(lazyModule).then(() => {
            // Re-trigger state change
            return $state.go(toState.name, toParams);
        });
    });
};

lazyLoadComponentsHook.$inject = ['$state', '$transitions', '$pfLazyLoad'];
