export const EMAIL_ROUTES = {
    'email': {
        abstract: true,
        module: 'email',
        parent: 'layout.empty',
        views: {
            'app@layout.empty': {
                // component: 'pfEmail',
                template: `<pf-email></pf-email>`,
            },
        },
        data: {
            appClass: 'email',
            module: 'email',
        },
    },
    'email.frequency': {
        url: '/email/frequency/:token',
        views: {
            'content': {
                // component: 'pfEmailFrequency',
                template: `<pf-email-frequency user="$resolve.user"></pf-email-frequency>`,
            },
        },
        resolve: {
            user: ['$pfUser', '$stateParams', '$q', ($pfUser, $stateParams, $q) => {
                let {token} = $stateParams;

                return $pfUser.getUserByToken(token)
                    .then(
                        (user) => {
                            let userData = user.data;
                            userData.token = token;
                            return userData;
                        },
                        (error) => {
                            return $q.reject(error);
                        }
                    );
            }]
        },
    },
};
