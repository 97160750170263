/**
 * Check state data to determine whether help chat launcher should be hidden or visible,
 */

// the names of the routes to disable help chat launcher
const disabledRoutes = [
    'onboarding',
    'onboarding.userInfo',
    'onboarding.profile',
    'onboarding.verify',
];

export const helpChatHook = ($transitions, $pfHelpChat, $pfUser) => {
    $transitions.onStart({}, trans => {
        const toState = trans.to();
        // hide help chat launcher if state name or state parent name matches disabledRoutes OR it's mobile
        if (
            $pfUser.isMobile() ||
            disabledRoutes.indexOf(toState.name) >= 0 ||
            disabledRoutes.indexOf(toState.parent) >= 0
        ) {
            $pfHelpChat.hideButton();
        } else {
            $pfHelpChat.showButton();
        }
    });
};

helpChatHook.$inject = ['$transitions', '$pfHelpChat', '$pfUser'];
