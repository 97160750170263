export const DASHBOARD_STATES = {
    'dashboard': {
        abstract: true,
        parent: 'layout.standard',
        module: 'dashboard',
        views: {
            'navbarMobile@layout.standard': {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "Home" | i18n }}
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'subnav@layout.standard': {
                // component: 'pfDashboardSubnav',
                template: `<pf-dashboard-subnav></pf-dashboard-subnav>`,
            },
            'app': {
                // component: 'pfDashboard',
                template: `<pf-dashboard></pf-dashboard>`,
            },
        },
        resolve: {
            userAbout: ['$pfUser', '$pfUserAbout', ($pfUser, $pfUserAbout) => {
                let user = $pfUser.getUser();

                if (!user) {
                    return {};
                }

                return $pfUserAbout.getMyAbout();
            }],
            userSkills: ['userAbout', (userAbout) => {
                if (userAbout && userAbout.skills) {
                    return userAbout.skills;
                }

                return [];
            }],
        },
        data: {
            appClass: 'dashboard',
            module: 'dashboard',
            requireAuth: true,
        },
        onEnter: ['$pfGameEngine', '$pfLayout', ($pfGameEngine, $pfLayout) => {
            // Set page title
            $pfLayout.title = 'Home';
            // Update the game engine
            $pfGameEngine.getResults();
        }],
    },
    'dashboard.suggestions': {
        url: '/suggestions',
        // component: 'pfDashboardSuggestions',
        template: `<pf-dashboard-suggestions user-skills="$resolve.userSkills"></pf-dashboard-suggestions>`,
        data: {
            trackPageName: 'Suggestions',
        }
    },
    'dashboard.activity': {
        url: '/activity',
        // component: 'pfDashboardActivity',
        template: `<pf-dashboard-activity user-skills="$resolve.userSkills"></pf-dashboard-activity>`,
        data: {
            trackPageName: 'Activity',
        },
    },
};
