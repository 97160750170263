export const TALENT_MATCH_JOB_SUMMARY_ROUTES = {
    'talentMatch.jobSummary': {
        url: '/job/:jobId',
        abstract: true,
        resolve: {
            jobId: ['$stateParams', $stateParams => $stateParams.jobId],
        },
        views: {
            '@talentMatch': {
                // component: 'pfJobSummary',
                template: `<pf-job-summary job-id="$resolve.jobId"></pf-job-summary>`,
            },
        },
        params: {},
        data: {
            sectionName: 'Job Summary',
        },
        onEnter: [
            '$pfLayout',
            $pfLayout => {
                // Set page title
                $pfLayout.title = 'Job Summary - TalentMatch';
            },
        ],
    },
    jobApplications: {
        parent: 'talentMatch.jobSummary',
        url: '/applications',
        // component: 'pfJobApplications',
        template: `<pf-job-applications job-id="$ctrl.jobId"></pf-job-applications>`,
    },
    jobApplicationDetails: {
        parent: 'jobApplications',
        url: '/:applicationId',
        // the following template will be rendered in the <ui-view="jobApplicationDetails"/>
        // in the job-applications.html template
        views: {
            jobApplicationDetails: {
                template:
                    '<pf-job-application-details job-id="$ctrl.jobId"></pf-job-application-details>',
            },
        },
    },
    jobInterested: {
        parent: 'talentMatch.jobSummary',
        url: '/interested',
        // component: 'pfJobInterested',
        template: `<pf-job-interested job-id="$resolve.jobId"></pf-job-interested>`,
    },
    jobMatches: {
        parent: 'talentMatch.jobSummary',
        url: '/matches',
        // component: 'pfJobMatches',
        template: `<pf-job-matches job-id="$resolve.jobId"></pf-job-matches>`,
    },
    jobOverview: {
        parent: 'talentMatch.jobSummary',
        url: '/overview',
        // component: 'pfJobOverview',
        template: `<pf-job-overview></pf-job-overview>`,
    },
};
