export const SEARCH_ROUTES = {
    search: {
        parent: 'layout.standard',
        url: '/search',
        views: {
            subnav: {
                // component: 'pfSearchSubnav',
                template: `<pf-search-subnav></pf-search-subnav>`,
            },
            navbarMobile: {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "Search" | i18n }}
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            app: {
                template: `<pf-search></pf-search>`,
            },
        },
        deepStateRedirect: {
            default: 'searchEntries',
        },
        data: {
            appClass: 'search',
            module: 'search',
        },
    },
    searchEntries: {
        parent: 'search',
        dynamic: true,
        url: '/work-samples?q&sort',
        // component: 'pfsearchEntries',
        template: `<pf-search-entries></pf-search-entries>`,
    },
    searchJobs: {
        resolve: {
            init: [
                '$state',
                '$pfEnv',
                ($state, $pfEnv) => {
                    if (!$pfEnv.jobsEnabled()) {
                        return $state.go('404');
                    }
                }
            ],
        },
        parent: 'search',
        dynamic: true,
        url:
            '/jobs?q&page&sort&type&company&industry&function&anywhere&distance&lat&lon&locale',
        // component: 'pfsearchJobs',
        template: `<pf-search-jobs></pf-search-jobs>`,
    },
    searchPeople: {
        parent: 'search',
        dynamic: true,
        url: '/people?q&page&sort',
        // component: 'pfsearchPeople',
        template: `<pf-search-people></pf-search-people>`,
    },
    searchCompanies: {
        resolve: {
            init: [
                '$state',
                '$pfEnv',
                ($state, $pfEnv) => {
                    if (!$pfEnv.jobsEnabled()) {
                        return $state.go('404');
                    }
                }
            ],
        },
        parent: 'search',
        dynamic: true,
        url: '/companies?q&page&sort',
        // component: 'pfsearchCompanies',
        template: `<pf-search-companies></pf-search-companies>`,
    },
    searchNetworks: {
        resolve: {
            init: [
                '$state',
                '$pfEnv',
                ($state, $pfEnv) => {
                    if ($pfEnv.inWalledGarden()) {
                        return $state.go('404');
                    }
                }
            ],
        },
        parent: 'search',
        dynamic: true,
        url: '/networks?q&page&sort&type',
        // component: 'pfsearchNetworks',
        template: `<pf-search-networks></pf-search-networks>`,
    },
};
