import {
    fetchPathway,
    onPathwayFetchSuccess,
    resetPathway,
} from './pathway-details/pathway-details.actions';

import {
    fetchBadge,
    onBadgeFetchSuccess,
    resetBadge,
} from './badge-details/badge-details.actions.js';

export const PATHWAYS_ROUTES = {
    pathways: {
        abstract: true,
        parent: 'layout.standard',
        views: {
            'app@layout.standard': {
                // component: 'pfPathways ',
                template: `<pf-pathways></pf-pathways>`,
            },
        },
        data: {
            appClass: 'pathways',
            module: 'pathways',
        },
    },
    'pathways.pathway': {
        url: '/pathway/:slug',
        params: {
            isTransactional: false,
        },
        onEnter: [
            '$transition$',
            '$pfPathways',
            '$ngRedux',
            '$state',
            '$pfLayout',
            ($transition$, $pfPathways, $ngRedux, $state, $pfLayout) => {
                // Set page title
                $pfLayout.title = 'Pathway';
                // get pathway slug and username
                const { slug } = $transition$.params();
                // check for params to exists
                if (!slug) {
                    $state.go('404');
                    return;
                }
                // dispatch fetchPathway to set loader
                $ngRedux.dispatch(fetchPathway());
                // get pathWay by slug & username
                $pfPathways
                    .getPathwayBySlug(slug)
                    .then(data => {
                        // get pathway title
                        const pathwayTitle = _.get(data, 'pathway.name');
                        // sanity check for title
                        if (pathwayTitle) {
                            // set title
                            $pfLayout.title = `Pathway - ${pathwayTitle}`;
                        }
                        // dospathc the new data
                        $ngRedux.dispatch(onPathwayFetchSuccess(data));
                    })
                    .catch(err => {
                        switch (err.status) {
                            case 403:
                                $state.go('403');
                                break;
                            case 404:
                                $state.go('404');
                                break;
                            default:
                                $state.go('404');
                                break;
                        }
                    });
            },
        ],
        views: {
            content: {
                // component: 'pfPathways ',
                template: `<pf-pathway-details></pf-pathway-details>`,
            },
        },
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear and Reset application's pathways
                $ngRedux.dispatch(resetPathway());
            },
        ],
        data: {
            trackPageName: 'Non-Transactional Pathway Details',
        },
    },
    'pathways.pathwayUser': {
        url: '/:username/pathway/:slug',
        params: {
            details: {},
            isTransactional: true,
        },
        onEnter: [
            '$transition$',
            '$pfPathways',
            '$ngRedux',
            '$state',
            '$pfLayout',
            ($transition$, $pfPathways, $ngRedux, $state, $pfLayout) => {
                // Set page default title
                $pfLayout.title = 'Pathway';
                // get pathway slug and username
                const { slug, username } = $transition$.params();
                // dispatch fetchPathway to set loader
                $ngRedux.dispatch(fetchPathway());
                // check for params to exists
                if (!slug || !username) {
                    $state.go('404');
                }
                // get pathWay by slug & username
                $pfPathways
                    .getPathwayBySlug(slug, username)
                    .then(data => {
                        // get pathway title
                        const pathwayTitle = _.get(data, 'pathway.name');
                        // sanity check for title
                        if (pathwayTitle) {
                            // set title
                            $pfLayout.title = `Pathway - ${pathwayTitle}`;
                        }
                        // dospathc the new data
                        $ngRedux.dispatch(onPathwayFetchSuccess(data));
                        // get user data
                        const pathwayUser = _.get(data, 'pathway_user', null);
                        const enrolledAt = _.get(
                            data,
                            'pathway_user.enrolled_at',
                            null,
                        );
                        // sanity check for user and enrolled
                        if (_.isNil(pathwayUser) || _.isEmpty(enrolledAt)) {
                            // go to the non-transactional
                            $state.go('pathways.pathway', { slug });
                        }
                    })
                    .catch(err => {
                        switch (err.status) {
                            case 403:
                                $state.go('403');
                                break;
                            case 404:
                                $state.go('404');
                                break;
                            case 405:
                                return $state.go('blocked', {
                                    blockedUser: err.data.blocked_user,
                                    blockedDate: err.data.blocked_date,
                                });
                            default:
                                $state.go('404');
                                break;
                        }
                    });
            },
        ],
        views: {
            content: {
                // component: 'pfPathways ',
                template: `<pf-pathway-details></pf-pathway-details>`,
            },
        },
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear and Reset application's pathways
                $ngRedux.dispatch(resetPathway());
            },
        ],
        data: {
            trackPageName: 'Transactional Pathway Details',
        },
    },
    'pathways.badgeDetails': {
        url: '/badge/:badgeSlug',
        params: {
            isTransactional: false,
        },
        onEnter: [
            '$transition$',
            '$pfPathways',
            '$ngRedux',
            '$state',
            '$pfLayout',
            ($transition$, $pfPathways, $ngRedux, $state, $pfLayout) => {
                // Set page title
                $pfLayout.title = 'Badge';
                // get badge Id
                const { badgeSlug } = $transition$.params();
                // dispatch fetchBadge to set loader
                if (badgeSlug) {
                    $ngRedux.dispatch(fetchBadge());
                    // get badge by slug
                    $pfPathways
                        .getBadge(badgeSlug)
                        .then(badgeDetailsUser => {
                            // get badge title
                            const badgeTitle = _.get(
                                badgeDetailsUser,
                                'badge.title',
                            );
                            // sanity check for badge title
                            if (badgeTitle) {
                                // set the title
                                $pfLayout.title = `Badge - ${badgeTitle}`;
                            }
                            // dispatch badge data
                            $ngRedux.dispatch(
                                onBadgeFetchSuccess(badgeDetailsUser),
                            );
                        })
                        .catch(err => {
                            switch (err.status) {
                                case 403:
                                    $state.go('403');
                                    break;
                                case 404:
                                    $state.go('404');
                                    break;
                                default:
                                    $state.go('404');
                                    break;
                            }
                        });
                } else {
                    $state.go('404');
                }
            },
        ],
        views: {
            content: {
                template: `<pf-pathway-badge-details class="pf-container pf-container--block"></pf-pathway-badge-details>`,
            },
        },
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear and Reset application's pathways
                $ngRedux.dispatch(resetBadge());
            },
        ],
        data: {
            trackPageName: 'Non-Transactional Badge Details',
        },
    },
    'pathways.badgeDetailsUser': {
        url: '/:username/badge/:badgeSlug',
        params: {
            isTransactional: true,
        },
        onEnter: [
            '$transition$',
            '$pfPathways',
            '$ngRedux',
            '$state',
            '$pfLayout',
            ($transition$, $pfPathways, $ngRedux, $state, $pfLayout) => {
                // Set page title
                $pfLayout.title = 'Badge';

                // get badge Id
                const { badgeSlug, username } = $transition$.params();
                // dispatch fetchBadge to set loader
                if (badgeSlug) {
                    $ngRedux.dispatch(fetchBadge());
                    // get badge by Id
                    $pfPathways
                        .getUserBadge(badgeSlug, username)
                        .then(badgeDetailsUser => {
                            // get badge title
                            const badgeTitle = _.get(
                                badgeDetailsUser,
                                'badge.title',
                            );
                            // sanity check for badge title
                            if (badgeTitle) {
                                // set the title
                                $pfLayout.title = `Badge - ${badgeTitle}`;
                            }
                            // dispatch badge data
                            $ngRedux.dispatch(
                                onBadgeFetchSuccess(badgeDetailsUser),
                            );
                            // get user data
                            const profile = _.get(
                                badgeDetailsUser,
                                'profile',
                                null,
                            );
                            // sanity check for user
                            if (_.isNil(profile)) {
                                // go to the non-transactional
                                $state.go('pathways.badgeDetails', {
                                    badgeSlug,
                                });
                            }
                        })
                        .catch(err => {
                            switch (err.status) {
                                case 403:
                                    $state.go('403');
                                    break;
                                case 404:
                                    $state.go('404');
                                    break;
                                case 405:
                                    return $state.go('blocked', {
                                        blockedUser: err.data.blocked_user,
                                        blockedDate: err.data.blocked_date,
                                    });
                                default:
                                    $state.go('404');
                                    break;
                            }
                        });
                } else {
                    $state.go('404');
                }
            },
        ],
        views: {
            content: {
                template: `<pf-pathway-badge-details class="pf-container pf-container--block"></pf-pathway-badge-details>`,
            },
        },
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear and Reset application's pathways
                $ngRedux.dispatch(resetBadge());
            },
        ],
        data: {
            trackPageName: 'Transactional Badge Details',
        },
    },
};
