export const MY_JOBS_ROUTES = {
    myJobs: {
        resolve: {
            init: [
                '$state',
                '$pfEnv',
                ($state, $pfEnv) => {
                    if (!$pfEnv.jobsEnabled()) {
                        return $state.go('404');
                    }
                }
            ],
        },
        parent: 'layout.standard',
        url: '/:username/jobs',
        onEnter: [
            '$state',
            '$pfUser',
            '$transition$',
            '$pfLayout',
            ($state, $pfUser, $transition$, $pfLayout) => {
                let user = $pfUser.getUser();
                let { username } = $transition$.params();

                // verify passed in username is the same as current user
                if (user.username !== username) {
                    return $state.go('403');
                }

                // Update title tag
                $pfLayout.title = 'My Jobs';
            },
        ],
        views: {
            'navbarMobile@layout.standard': {
                // component: 'pfNavbarMobile'
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>
                            {{ "My Jobs" | i18n }}
                        </navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'subnav@layout.standard': {
                // component: 'pfMyJobsSubnav',
                template: `<pf-my-jobs-subnav></pf-my-jobs-subnav>`,
            },
            'app@layout.standard': {
                template: `<pf-my-jobs></pf-my-jobs>`,
            },
        },
        deepStateRedirect: {
            default: 'myJobsInterested',
            params: true,
        },
        data: {
            appClass: 'my-jobs',
            module: 'my-jobs',
        },
    },
    myJobsInterested: {
        parent: 'myJobs',
        url: '/interested',
        // component: 'pfMyJobsInterested',
        template: `<pf-my-jobs-interested></pf-my-jobs-interested>`,
    },
    myJobsApplied: {
        parent: 'myJobs',
        url: '/applied',
        // component: 'pfMyJobsApplied',
        template: `<pf-my-jobs-applied></pf-my-jobs-applied>`,
    },
    myJobsHired: {
        parent: 'myJobs',
        url: '/hired',
        // component: 'pfMyJobsHired',
        template: `<pf-my-jobs-hired></pf-my-jobs-hired>`,
    },
};
