import {TALENT_MATCH_COMPETENCY_LIBRARY_ROUTES} from '../../competency-library/js/config/routes.config';
import {TALENT_MATCH_DASHBOARD_ROUTES} from '../../dashboard/js/config/routes.config';
import {TALENT_MATCH_JOB_MANAGEMENT_ROUTES} from '../../job-management/js/routes.config';
import {TALENT_MATCH_JOB_SUMMARY_ROUTES} from '../../job-summary/js/config/routes.config';
import {TALENT_MATCH_JOB_WIZARD_ROUTES} from '../../job-wizard/js/config/routes.config';
import {TALENT_MATCH_SEARCH_ROUTES} from '../../search/js/config/routes.config';
import {TALENT_MATCH_SETTINGS_ROUTES} from '../../settings/js/config/routes.config';

import TalentMatchTemplate from '../template/talent-match.html';

// Define parent TalentMatch state
const TALENT_MATCH_PARENT_STATE = {
    'talentMatch': {
        parent: 'layout.standard',
        abstract: true,
        module: 'talent-match',
        views: {
            'app@layout.standard': {
                template: TalentMatchTemplate,
            },
        },
        data: {
            appClass: 'talent-match',
            module: 'talent-match',
        },
        resolve: {
            'currentCompany': ['$pfCompanyAdmin', '$pfSubscription', ($pfCompanyAdmin, $pfSubscription) => {
                let companyIds = $pfCompanyAdmin.getAuthorizedCompanyIds();

                return $pfSubscription.getCompanyById(companyIds[0]);
            }],
        },
    },
};

// Export full TalentMatch state tree
export const TALENT_MATCH_ROUTES = _.assign({},
    TALENT_MATCH_PARENT_STATE,
    TALENT_MATCH_COMPETENCY_LIBRARY_ROUTES,
    TALENT_MATCH_DASHBOARD_ROUTES,
    TALENT_MATCH_JOB_MANAGEMENT_ROUTES,
    TALENT_MATCH_JOB_WIZARD_ROUTES,
    TALENT_MATCH_JOB_SUMMARY_ROUTES,
    TALENT_MATCH_SEARCH_ROUTES,
    TALENT_MATCH_SETTINGS_ROUTES,
);
