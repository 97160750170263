/**
 * Register a hook to add a body class
 */
export const bodyClassHook = ($transitions, $rootScope) => {
    // Register the hook
    $transitions.onSuccess({}, trans => {
        const toState = trans.to();
        let appClass = null;

        if (
            toState.$$state().includes &&
            toState.$$state().includes.layout === true
        ) {
            // set default app class if we have a layout
            appClass = 'pf-app--default';
        }

        // if the state has appClass defined, then use it
        if (toState.data && toState.data.appClass) {
            appClass = 'pf-app--' + toState.data.appClass;
        }

        if (!!appClass) {
            $rootScope.appClass = appClass;
        }
    });
};

bodyClassHook.$inject = ['$transitions', '$rootScope'];
