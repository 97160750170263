export const TALENT_MATCH_JOB_WIZARD_ROUTES = {
    'talentMatch.addJob': {
        url: '/job/add',
        data: {
            'sectionName': 'Post Job',
        },
        views: {
            '@talentMatch': {
                // component: 'pfJobWizard',
                template: `<pf-job-wizard job="$resolve.job"></pf-job-wizard>`,
            },
        },
        resolve: {
            'job': () => {
                return {};
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Post Job - TalentMatch';
        }],
    },
    // Used only for off the street employer sign ups, focusing them on creating and posting their first job
    'talentMatch.jobAddFirst': {
        url: '/job/add/first',
        data: {
            'sectionName': 'Post Job',
            'searchAccessRestricted': true,
        },
        views: {
            'layout@': {
                // component: 'pfJobWizard',
                template: `
                    <md-content id="pf-talent-match-container" layout="column" class="pf-content pf-talent-match-container" flex>
                        <pf-job-wizard job="$resolve.job"></pf-job-wizard>
                    </md-content>
                `,
            },
        },
        resolve: {
            'job': () => {
                return {};
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Post Job - TalentMatch';
        }],
    },
    'talentMatch.editJob': {
        url: '/job/edit/:jobId',
        data: {
            'sectionName': 'Edit Job',
        },
        views: {
            '@talentMatch': {
                // component: 'pfJobWizard',
                template: `<pf-job-wizard job="$resolve.job"></pf-job-wizard>`,
            },
        },
        resolve: {
            'job': ['$stateParams', '$pfJobs', ($stateParams, $pfJobs) => {
                let {jobId} = $stateParams;

                return $pfJobs.getJobById(jobId);
            }],
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Edit Job - TalentMatch';
        }],
    },
    'talentMatch.editJobFirst': {
        url: '/job/edit/:jobId/first',
        data: {
            'sectionName': 'Edit Job',
            'searchAccessRestricted': true,
        },
        views: {
            'layout@': {
                // component: 'pfJobWizard',
                template: `
                    <md-content id="pf-talent-match-container" layout="column" class="pf-content pf-talent-match-container" flex>
                        <pf-job-wizard job="$resolve.job"></pf-job-wizard>
                    </md-content>
                `,
            },
        },
        resolve: {
            'job': ['$stateParams', '$pfJobs', ($stateParams, $pfJobs) => {
                let {jobId} = $stateParams;

                return $pfJobs.getJobById(jobId);
            }],
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Edit Job - TalentMatch';
        }],
    },
    'talentMatch.previewJob': {
        url: '/job/preview/:jobId',
        data: {
            'sectionName': 'Publish Job',
        },
        views: {
            '@talentMatch': {
                // component: 'pfJobPreview,
                template: `<pf-job-preview preview="$resolve.preview"></pf-job-preview>`,
            },
        },
        resolve: {
            'preview': [
                '$stateParams',
                '$pfJobs',
                '$pfUser',
                '$pfSubscription',
                '$state', (
                    $stateParams,
                    $pfJobs,
                    $pfUser,
                    $pfSubscription,
                    $state
                ) => {
                    let {jobId} = $stateParams;
                    let userId = $pfUser.getUser().id;

                    return $pfJobs.getJobPreviewById(jobId).then((preview) => {
                        // sanity check if the logged in user is not the job owner
                        // TODO: check if the subscribers.all_jobs value is true, we'll need to see if the userId is an admin of the company rather than the job owner
                        if (userId !== preview.job.fk_user_id) {
                            return $state.go('403');
                        }

                        // sanity check if the subscriber can post for free
                        if ($pfSubscription.postForFree) {
                            // should never have been directed to this page
                            $state.go('jobOverview', {jobId: jobId});
                        }

                        return preview;
                    }, () => {
                        return $state.go('404');
                    });
                }
            ],
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Publish Job - TalentMatch';
        }],
    },
    // Used only for off the street employer sign ups, focusing them on creating and posting their first job
    'talentMatch.previewJobFirst': {
        url: '/job/preview/:jobId/first',
        data: {
            'sectionName': 'Publish Job',
            'searchAccessRestricted': true,
        },
        views: {
            'layout@': {
                // component: 'pfJobPreview,
                template: `
                    <md-content id="pf-talent-match-container" layout="column" class="pf-content pf-talent-match-container" flex>
                        <pf-job-preview preview="$resolve.preview"></pf-job-preview>
                    </md-content>
                `,
            },
        },
        resolve: {
            'preview': ['$stateParams', '$pfJobs', '$pfUser', '$state', ($stateParams, $pfJobs, $pfUser, $state) => {
                let {jobId} = $stateParams;
                let userId = $pfUser.getUser().id;

                return $pfJobs.getJobPreviewById(jobId).then((preview) => {
                    if (userId !== preview.job.fk_user_id) {
                        return $state.go('403');
                    }

                    return preview;
                }, () => {
                    return $state.go('404');
                });

            }],
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Publish Job - TalentMatch';
        }],
    },
};
