export const TALENT_MATCH_JOB_MANAGEMENT_ROUTES = {
    'talentMatch.jobManagement': {
        url: '/jobs/manage?filter&page',
        views: {
            '@talentMatch': {
                // component: 'pfJobManagement',
                template: `<pf-job-management></pf-job-management>`,
            },
        },
        params: {
            filter: {
                value: 'active',
                squash: true,
            },
            page: {
                value: '1',
                squash: true,
            },
        },
        data: {
            sectionName: 'Jobs',
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Jobs - TalentMatch';
        }],
    },
};
