import { setCourse } from './course.actions';

export const COURSE_ROUTES = {
    course: {
        parent: 'layout.standard',
        url: '/course/:slug?sort&status',
        params: {
            status: {
                value: '',
                dynamic: true,
                squash: true,
            },
            sort: {
                value: 'duedate',
                dynamic: true,
                squash: true,
            },
        },
        data: {
            appClass: 'course',
            module: 'course',
        },
        onEnter: [
            '$pfLayout',
            '$pfCourse',
            '$transition$',
            '$ngRedux',
            ($pfLayout, $pfCourse, $transition$, $ngRedux) => {
                // get store state
                const state = $ngRedux.getState();
                // try to get the course id
                const oldSlug = _.get(state, 'course.meta.slug');
                // get course slug
                const { slug: newSlug } = $transition$.params();
                // sanity check for refresh page from applying filters
                if (oldSlug === newSlug) {
                    // dont do anything
                    return;
                }
                // get course
                $pfCourse.getCourseBySlug(newSlug).then(course => {
                    // set page title
                    $pfLayout.title = `Course - ${course.course.course}`;
                    // dispatch course
                    $ngRedux.dispatch(setCourse(course));
                });
            },
        ],
        views: {
            'app@layout.standard': {
                template: `<pf-course></pf-course>`,
            },
            'navbarMobile@layout.standard': {
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>{{ "Tasks" | i18n }}</navbar-title>
                    </pf-navbar-mobile>
                `,
            },
        },
    },
};
