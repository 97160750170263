import {
    companyRequest,
    updateCompany,
    resetCompany,
} from './company-details.actions';

export const COMPANY_ROUTES = {
    company: {
        parent: 'layout.standard',
        url: '/company/:slug',
        onEnter: [
            '$ngRedux',
            '$pfCompanyAdmin',
            '$transition$',
            '$pfLayout',
            ($ngRedux, $pfCompanyAdmin, $transition$, $pfLayout) => {
                // Update application state while company loads
                $ngRedux.dispatch(companyRequest());
                // Get company using slug
                $pfCompanyAdmin
                    .getCompanyBySlug($transition$.params().slug)
                    .then(
                        company => {
                            // Update application state with company
                            $ngRedux.dispatch(updateCompany(company));
                            // Set page title
                            $pfLayout.title = `Get a job at ${company.name}`;
                        },
                        () => {
                            return $state.go('404');
                        },
                    );
            },
        ],
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // Clear application state so there's no lingering model when switching between companies
                $ngRedux.dispatch(resetCompany());
            },
        ],
        views: {
            'app@layout.standard': {
                template: `<pf-company></pf-company>`,
            },
        },
        data: {
            appClass: 'company',
            module: 'company',
        },
    },
};
