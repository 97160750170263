export const AUTH_PAGES_ROUTES = {
    'authPages': {
        abstract: true,
        parent: 'layout.standard',
        views: {
            'app@layout.standard': {
                // component: 'pfAuthPages',
                template: `<pf-auth-pages></pf-auth-pages>`,
            },
        },
        data: {
            appClass: 'auth-pages',
            module: 'auth-pages',
        },
    },
    'authPages.forgotPassword': {
        url: '/forgot',
        views: {
            'content': {
                // component: pfForgotPassword
                template: '<pf-forgot-password></pf-forgot-password>',
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            // Set page title
            $pfLayout.title = `Forgotten Password Recovery`;
        }],
    },
    'authPages.resetPassword': {
        url: '/reset/:token',
        views: {
            'content': {
                // component: pfResetPassword
                template: '<pf-reset-password></pf-reset-password>',
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            // Set page title
            $pfLayout.title = `Reset Your Password`;
        }],
    },
    'authPages.welcomeEmployer': {
        url: '/welcome/employer',
        views: {
            'navbarMobile@layout.standard': {
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>{{ "Thank You" | i18n }}<navbar-title>
                    </pf-navbar-mobile>
                `,
            },
            'content': {
                // component: 'pfWelcomeEmployer',
                template: '<pf-welcome-employer></pf-welcome-employer>',
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            // Set page title
            $pfLayout.title = `Welcome to Portfolium`;
        }],
    },
    'authPages.expiredActivationToken': {
        url: '/activate/expired',
        views: {
            'content': {
                // component: pfExpiredActivationToken
                template: '<pf-expired-activation-token></pf-expired-activation-token>',
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            // Set page title
            $pfLayout.title = `Invalid Confirmation Token`;
        }],
    },
    'authPages.emailUnsubscribe': {
        url: '/unsubscribe/:type/:token?saved_search_id',
        views: {
            'content': {
                // component: 'pfEmailUnsubscribe',
                template: `<pf-email-unsubscribe></pf-email-unsubscribe>`,
            },
        },
    },
    'authPages.emailConfirm': {
        url: '/confirm/email/:id',
        views: {
            'content': {
                // component: 'pfEmailConfirm',
                template: `<pf-email-confirm></pf-email-confirm>`,
            },
        },
    },
};
