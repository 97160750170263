export const ENTRY_DETAILS_ROUTES = {
    /*
        NOTE: Do not transition to this state manually; Call 'entryDetailsPage.open'
              and it will handle the appropriate transition based on screen size
    */
    entryDetailsPage: {
        url: '/entry/{slug:(?!add$|edit/).*}?post&ad&version',
        params: {
            isMobile: null,
            ad: null,
            version: null,
        },
        resolve: {
            isMobile: [
                '$pfUser',
                $pfUser => {
                    return $pfUser.isMobile();
                },
            ],
            openEntryModal: [
                'isMobile',
                '$stateParams',
                '$pfEntryDetailsPanelService',
                (isMobile, $stateParams, $pfEntryDetailsPanelService) => {
                    const { slug, ad, version } = $stateParams;

                    if (isMobile) {
                        // Pass through and let onEnter hook redirect to mobile state
                        return false;
                    }

                    $pfEntryDetailsPanelService.open(slug, ad, version);

                    return true;
                },
            ],
        },
        redirectTo: transition => {
            // go to the mobile state if we detect a mobile device
            const { slug, ad, version } = transition
                .injector()
                .get('$stateParams');
            const $pfUser = transition.injector().get('$pfUser');
            const isMobile = $pfUser.isMobile();

            if (isMobile) {
                return {
                    state: 'entryDetailsPage.mobile.standard',
                    params: {
                        slug,
                        ad,
                        isMobile,
                        version,
                    },
                };
            }
        },
    },

    /*
        NOTE: Do not transition to this state manually; Call 'entryDetailsPage.open'
              and it will handle the appropriate transition based on screen size

        This is mainly for empty previous state (permalink). If we don't specify the main
        layout view, we have no place to insert our view. This is because we don't use
        the standard layout on the entryDetailsPage route because we want the panel
        to open on top of the current page.
    */
    'entryDetailsPage.mobile': {
        resolve: {
            params: [
                '$stateParams',
                $stateParams => {
                    const { ad, slug, version } = $stateParams;
                    return { ad, slug, version };
                },
            ],
        },
        views: {
            'layout@': {
                template: `
                    <div layout="row" flex>
                        <pf-layout-standard>
                        </pf-layout-standard>
                    </div>`,
            },
        },
    },

    /*
        NOTE: Do not transition to this state manually; Call 'entryDetailsPage.open'
              and it will handle the appropriate transition based on screen size
    */
    'entryDetailsPage.mobile.standard': {
        // get the entry data from the resolve in entryDetailsPage route
        resolve: {
            params: [
                '$stateParams',
                $stateParams => {
                    const { ad, slug, version } = $stateParams;
                    return { ad, slug, version };
                },
            ],
        },
        views: {
            app: {
                template: `
                    <pf-entry-details-mobile
                        slug="{{ $resolve.params.slug }}"
                        version="$resolve.params.version"
                        options="$resolve.params.options">
                    </pf-entry-details-mobile>
                `,
            },
        },
    },
};
