export class VersionService {
    constructor($http, $q, $window, $mdToast, $state) {
        this.$http = $http;
        this.$q = $q;
        this.$window = $window;
        this.$mdToast = $mdToast;
        this.$state = $state;
        this.isUpdateAvailable = false;
        this.updateToastVisible = false;

        // the names of the routes to disable update toast
        this.disabledRoutes = [
            'entryEditor',
            'entryEditor.draft',
            'entryEditor.edit',
        ];
    }

    /**
     * Check if there is a new version of the app
     * @return {Promise<void>} Promise resolved when a new version is available
     */
    checkForNewVersion() {
        if (this.isUpdateAvailable) {
            // We already know the app is out of date -- resolve promise immediately
            return this.$q.resolve(false);
        }

        return this.getVersion()
            .then(assets => {
                if (this.isCurrentVersion(assets.hash)) {
                    // No new updates
                    return this.$q.resolve(false);
                }

                // Set flag so no additional API calls are made
                this.isUpdateAvailable = true;

                return this.$q.resolve(true);
            })
            .catch(err => {
                return this.$q.reject(err);
            });
    }

    /**
     * Get the current assets version, and a map of all the current modules
     * @return {Promise<Object>}
     */
    getVersion() {
        return this.$http
            .get('/system/version')
            .then(response => {
                return this.$q.resolve(response.data);
            })
            .catch(err => {
                return this.$q.reject(err);
            });
    }

    /**
     * Determine whether the currently loaded assets are up to date with the latest
     * deployed assets
     * @param  {String}  hash Assets hash returned by server
     * @return {Boolean}      Are the assets currently loaded up to date?
     */
    isCurrentVersion(hash) {
        if (!_.isString(hash)) {
            return true;
        }

        return __webpack_hash__ === hash;
    }

    /**
     * Show a toast that prompts the user to update to the latest assets version
     * by refreshing the app
     * @return {Promise<void>}
     */
    showUpdateToast() {
        if (this.updateToastVisible) {
            return;
        }

        if (this.disabledRoutes.indexOf(this.$state.current.name) >= 0) {
            return;
        }

        const toast = this.$mdToast
            .simple()
            .textContent('A new version of Portfolium is available!')
            .action('Update')
            .actionKey('r')
            .actionHint('Press the Control-"r" key combination to ')
            .position('bottom left')
            .hideDelay(false);

        this.updateToastVisible = true;

        return this.$mdToast
            .show(toast)
            .then(() => {
                this.$window.location.reload();
            })
            .catch(err => {
                return this.$q.reject(err);
            })
            .finally(() => {
                this.updateToastVisible = false;
            });
    }
}

VersionService.$inject = ['$http', '$q', '$window', '$mdToast', '$state'];
