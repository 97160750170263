export const TALENT_MATCH_COMPETENCY_LIBRARY_ROUTES = {
    'talentMatch.competencyLibrary': {
        url: '/competency-library',
        data: {
            'sectionName': 'Competency Library',
        },
        views: {
            '@talentMatch': {
                // component: 'pfCompetencyLibrary',
                template: `
                    <pf-competency-library></pf-competency-library>
                `,
            },
        },
        onEnter: ['$pfLayout', ($pfLayout) => {
            $pfLayout.title = 'Competency Library - TalentMatch';
        }],
    },
};
