import {
    resetAssignment,
    fetchAssignment,
    onAssignmentFetchSuccess,
} from './assignment.actions';

import {
    addSubmission,
    addFeedback,
    fetchRequirement,
    onRequirementFetchSuccess,
    resetRequirement,
    updateActions,
} from '../pathways/pathway-requirement/pathway-requirement.actions';

import { onPathwayFetchSuccess } from '../pathways/pathway-details/pathway-details.actions';

export const ASSIGNMENT_ROUTES = {
    assignment: {
        parent: 'layout.standard',
        url: '/course/:courseSlug/assignment/:assignmentId',
        data: {
            appClass: 'assignment',
            module: 'assignment',
        },
        params: {
            courseSlug: {
                value: '',
                dynamic: true,
            },
        },
        resolve: {
            type: [
                'PF_ASSIGNMENT_SERVICES',
                PF_ASSIGNMENT_SERVICES => PF_ASSIGNMENT_SERVICES.assignment,
            ],
            assignment: [
                '$pfAssignments',
                '$transition$',
                '$ngRedux',
                'PF_ENTRY_VERSION_STATES',
                (
                    $pfAssignments,
                    $transition$,
                    $ngRedux,
                    PF_ENTRY_VERSION_STATES,
                ) => {
                    // start the loading
                    $ngRedux.dispatch(fetchAssignment());
                    // get assignment id
                    const { assignmentId } = $transition$.params();
                    // set the version to get as the current submitted version
                    const version = PF_ENTRY_VERSION_STATES.CURRENT;
                    // Get assignment using id
                    return $pfAssignments
                        .getAssignment(assignmentId, {
                            version,
                        })
                        .then(assignment => {
                            // add the assignment
                            $ngRedux.dispatch(
                                onAssignmentFetchSuccess(assignment),
                            );
                            return assignment;
                        });
                },
            ],
        },
        redirectTo: transition => {
            // get assignment id
            const { courseSlug, assignmentId } = transition.params();
            const assignmentPromise = transition
                .injector()
                .getAsync('assignment');

            return assignmentPromise
                .then(assignment => {
                    // get real course slug
                    const { course_slug: newCourseSlug } = assignment;
                    // compare it with the one sent
                    if (courseSlug !== newCourseSlug) {
                        return {
                            state: 'assignment',
                            params: {
                                assignmentId,
                                courseSlug: newCourseSlug,
                            },
                        };
                    }
                    return null;
                })
                .catch(() => '404');
        },
        onEnter: [
            'assignment',
            '$pfLayout',
            (assignment, $pfLayout) => {
                // get real course slug
                const { assignment_title: title } = assignment;

                $pfLayout.title = `Assignment - ${title}`;
            },
        ],
        onExit: [
            '$ngRedux',
            $ngRedux => {
                // add the assignment
                $ngRedux.dispatch(resetAssignment());
            },
        ],
        views: {
            'app@layout.standard': {
                template: `<pf-assignment type="$resolve.type"></pf-assignment>`,
            },
            'navbarMobile@layout.standard': {
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>{{ "Tasks" | i18n }}</navbar-title>
                    </pf-navbar-mobile>
                `,
            },
        },
    },
    requirement: {
        parent: 'layout.standard',
        url: '/:username/pathway/:slug/requirement/:requirementId',
        data: {
            appClass: 'assignment',
            module: 'assignment',
        },
        resolve: {
            type: [
                'PF_ASSIGNMENT_SERVICES',
                PF_ASSIGNMENT_SERVICES => PF_ASSIGNMENT_SERVICES.requirement,
            ],
        },
        onEnter: [
            '$transition$',
            '$pfPathways',
            '$ngRedux',
            '$state',
            '$pfLayout',
            'PF_PATHWAY_REQUIREMENT_TYPES',
            'PF_PATHWAY_REQUIREMENT_STATUS_MAPPED',
            (
                $transition$,
                $pfPathways,
                $ngRedux,
                $state,
                $pfLayout,
                PF_PATHWAY_REQUIREMENT_TYPES,
                PF_PATHWAY_REQUIREMENT_STATUS_MAPPED,
            ) => {
                const { slug, requirementId } = $transition$.params();
                // Set page title
                $pfLayout.title = 'Pathway Requirement';

                // TODO: MAKE SURE THAT THE SLUG IS FOR THAT REQUIREMENT
                //  WHEN REQUIREMENT IT COMES BACK FROM THE API
                // sanity check
                if (slug && requirementId) {
                    // start the loading
                    $ngRedux.dispatch(fetchRequirement());
                    // get requirement
                    $pfPathways
                        .getRequirementById(requirementId)
                        .then(
                            ({
                                profile,
                                pathway,
                                network,
                                submission,
                                actions,
                                feedback,
                            }) => {
                                // find the requirement
                                const requirement = $pfPathways.findRequirementInPathway(
                                    requirementId,
                                    pathway,
                                );

                                /**
                                 * IMPORTANT! Super DUMB HACK incoming:
                                 * If this is an experience requirement type and submission status is SUBMITTED, manually set status to NOT STARTED.
                                 * 
                                 * I hate this and myself for doing it but this seems to be the cleanest/fastest way to modify the 
                                 * requirement presentational views (Review/Status/Instructions templates) for this requirement type.
                                 * 
                                 * The API needs to be updated to handle experience requirements properly
                                 * If someone is stuck trying to figure out why the status is different than whats returned from the API... I am so sorry.
                                 */
                                const isExperienceType = requirement.type === PF_PATHWAY_REQUIREMENT_TYPES.experience;
                                const isSubmittedStatus = submission.status === PF_PATHWAY_REQUIREMENT_STATUS_MAPPED.submitted;
                                if (isExperienceType && isSubmittedStatus) {
                                    submission.status = PF_PATHWAY_REQUIREMENT_STATUS_MAPPED.notStarted;
                                }
                                /**
                                 * End of super dumb hack
                                 */

                                // get requirement title
                                const requirementTitle = _.get(
                                    requirement,
                                    'requirement.name',
                                );
                                // sanity check for badge title
                                if (requirementTitle) {
                                    // set the title
                                    $pfLayout.title = `Pathway Requirement - ${requirementTitle}`;
                                }
                                // dispatch pathway
                                $ngRedux.dispatch(
                                    onPathwayFetchSuccess({
                                        pathway,
                                        network,
                                        pathway_user: profile,
                                    }),
                                );
                                // dispatch actions
                                $ngRedux.dispatch(addSubmission(submission));
                                $ngRedux.dispatch(addFeedback(feedback));
                                $ngRedux.dispatch(updateActions(actions));
                                $ngRedux.dispatch(
                                    onRequirementFetchSuccess(requirement),
                                );
                            },
                        )
                        .catch(err => {
                            switch (err.status) {
                                case 403:
                                    // go to error
                                    $state.go('403');
                                    break;
                                case 404:
                                    // go to error
                                    $state.go('404');
                                    break;
                                default:
                                    // go to error
                                    $state.go('404');
                                    break;
                            }
                        });
                } else {
                    // go to error
                    $state.go('404');
                }
            },
        ],
        onExit: [
            '$ngRedux',
            $ngRedux => {
                $ngRedux.dispatch(resetRequirement());
            },
        ],
        views: {
            'app@layout.standard': {
                template: `<pf-assignment type="$resolve.type"></pf-assignment>`,
            },
            'navbarMobile@layout.standard': {
                template: `
                    <pf-navbar-mobile>
                        <navbar-title>{{ "Tasks" | i18n }}</navbar-title>
                    </pf-navbar-mobile>
                `,
            },
        },
    },
};
